@import "../../../variables";

#auth-header {
  background: $primary-color url("../../../assets/images/header-background.png") no-repeat bottom;
  box-shadow: inset 0 0 0 1000px #002764ab;
  min-height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error {
    width: 100%;
    background-color: $secondary-dark-color;
    color: $primary-color;
    font-size: 13px;
    font-weight: 700;
    font-style: italic;
    padding: 10px;
    text-align: center;
    margin-bottom: auto;
  }

  .title {
    text-shadow: 0 0 3px #000854;
    color: $secondary-color;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .sub-title {
    text-shadow: 0 0 6px #00217b;
    color: $white;
    font-size: 11px;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 15px;
  }

  @media (min-width: 768px) {
    min-height: 128px;

    .title {
      font-size: 24px;
    }

    .sub-title {
      font-size: 12px;
    }
  }

  @media (min-width: 1024px) {
    min-height: 76px;

    .title {
      font-size: 22px;
    }

    .sub-title {
      font-size: 11px;
    }
  }

  @media (min-width: 1280px) {
    min-height: 128px;

    .title {
      font-size: 29px;
    }

    .sub-title {
      font-size: 14px;
    }
  }
}
