#test-page {
  background: url(https://s3-eu-west-1.amazonaws.com/static.4p/fenerbahce-landing-bg.png) no-repeat center;
  background-size: cover;
  display: flex;
  flex-grow: 1;
  width: 100%;

  .page-content {
    align-items: center;
    padding: 0 40px 20px 40px;

    &.cover {
      padding-bottom: 20px;
    }

    #test-question {
      width: 100%;
      max-width: 550px;
    }
  }

  @media (min-width: 768px) {

    .page-content {

      &.cover {
        padding-bottom: 50px;
      }
    }
  }

  @media (min-width: 1200px) {
    .page-content {

      &.cover {
        width: 1000px !important;
        padding: 10px;
      }
    }
  }
}
