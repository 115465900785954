@import "../../../../variables";

#poll-history-tab {
  display: flex;
  flex-direction: column;
  padding: 25px 20px 20px 25px;
  min-height: 460px;
  max-height: 460px;
  overflow-x: hidden;
  overflow-y: scroll;
  color: $primary-color;

  .empty-history {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .sub-text {
      font-size: 14px;
      line-height: 24px;
    }

    .logo {
      width: 64px;
      height: 64px;
      margin-top: 30px;
    }
  }

  .more-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;

    .more-text {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
    }

    .more-icon {
      width: 24px;
      height: 24px;
    }
  }

  .divider {
    width: 100%;
    margin: 30px 0;
  }
}
