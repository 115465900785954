@import "../../../../variables";

.consent-modal {
  width: 312px !important;

  .ant-modal-content {
    border-radius: 9px;

    .ant-modal-body {
      padding: 0;

      .body {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        $logoDim: 64px;

        .header {
          position: relative;
          min-height: 50px;
          width: 100%;
          border-top-left-radius: 9px;
          border-top-right-radius: 9px;
          background-color: $primary-color;

          .logo {
            width: $logoDim;
            height: $logoDim;
            position: absolute;
            bottom: -$logoDim / 2;
            left: 50%;
            transform: translate(-50%, 0%);
            border-radius: 100px;
          }
        }

        .message {
          font-size: 14px;
          line-height: 17px;
          color: #474747;
          margin-top: $logoDim / 1.5;
          padding: 0 24px;
        }

        .checkbox-wrapper {
          display: flex;
          width: 100%;
          padding: 0 24px;
          margin-bottom: 12px;

          .ant-checkbox-wrapper {

            .ant-checkbox {
              margin-right: 8px;
              border: 1px solid $primary-color;

              &.ant-checkbox-checked {

                .ant-checkbox-inner {
                  background-color: $secondary-color;
                  border-color: $secondary-color;
                }
              }
            }

            .ant-checkbox-inner {
              background-color: $white;
              border-color: $white;

              &:after {
                border-color: $primary-color;
              }
            }
          }

          .text {
            text-align: left;
            font-size: 12px;
            line-height: 125%;
            color: $primary-color;
            padding-top: 3px;

            a {
              font-weight: bold;
            }
          }
        }

        .button {
          background-color: $primary-color;
          border: none;
          color: $white;
          height: 33px;
          border-radius: 8px;
          font-size: 14px;
          line-height: 17px;
          font-weight: bold;
          width: fit-content;
          min-width: 80px;
          margin: 10px auto 8px;

          .ant-spin {

            .ant-spin-dot-item {
              background-color: $white;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    width: 320px !important;
  }
}
