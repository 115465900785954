@import "../../variables";

#email-confirm-page {
    .page-content {
        padding-top: 40px;
        align-items: center;
        .section-email-confirm {
            .info {
                .title {
                    font-size: 14px;
                    color: $primary-color;
                    font-weight: 400;
                    letter-spacing: -0.7px;
                    line-height: normal;
                }
            }
        }
    }
    @media (min-width: 768px) { 
        .page-content {
            .section-email-confirm {
                .info {
                    .title {
                        font-size: 21px;
                        letter-spacing: -1.05px;
                    }
                }
            }
        }
    }
}