@import "../../../variables";

#notification-card {
  display: flex;
  margin-bottom: 4px;
  background: $white;
  width: 100%;

  &.marked {

    .left-indicator {
      background-color: $secondary-color;
    }

    .content {

      .header,
      .description {
        color: $white;
      }

      .footer {

        .date {
          color: $white;
        }

        .link-button {
          color: $white;
          border: 1px solid $white !important;
        }
      }
    }
  }

  .left-indicator {
    min-width: 4px;
    max-width: 4px;
    background-color: $primary-color;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 15px 20px;

    .image {
      max-width: 100%;
    }

    .header {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: $primary-color;
      margin-bottom: 4px;
    }

    .description {
      font-size: 12px;
      line-height: 18px;
      color: $primary-color;
      margin-bottom: 12px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .date {
        font-weight: 500;
        font-size: 9px;
        line-height: 11px;
        color: $primary-color;
      }

      .link-button {
        display: flex;
        justify-content: center;
        min-width: 65px;
        padding: 2px 8px;
        border: 1px solid $primary-color;
        border-radius: 4px;
        font-size: 10px;
        line-height: 18px;

        .arrow {
          margin-left: 8px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 8px;

    .content {

      .header {
        font-size: 15px;
        line-height: 22px;
      }

      .description {
        font-size: 14px;
        line-height: 18px;
      }

      .footer {

        .date {
          font-size: 11px;
          line-height: 13px;
        }

        .link-button {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
