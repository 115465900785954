@import "../../variables";

#home-page {
  $border: 1px solid lightgray;

  .page-content {
    padding-bottom: 18px;

    .section {
      display: flex;
      flex-direction: column;
      background-color: $white;
      margin-bottom: 6px;
      box-shadow: 0 0 3px rgba(123, 155, 209, 0.4);

      .heading {
        display: flex;
        padding: 15px;
        border-bottom: $border;
        align-items: center;

        img {
          min-width: 52px;
          width: 52px;
          margin-right: 8px;
        }

        .info {
          display: flex;
          flex-direction: column;
          color: $primary-color;

          .title {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: -0.7px;
          }

          .text {
            font-size: 12px;
            letter-spacing: -0.6px;
          }
        }
      }

      &.section-share {
        .body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .image-left {
            cursor: pointer;
            max-width: 50%;
            height: auto;
            padding: 10px;
            border-right: $border;
          }

          #personality,
          #personality-small {
            width: 50%;
            min-width: 50%;
            max-width: 50%;

            .personality-button {
              max-width: 130px;
            }
          }

          #user-fan-card {
            display: flex;
            align-items: center;
            padding: 10px;

            .user-info-wrapper {
              left: 20px;
              bottom: 20px;

              .fullName {
                font-size: 5px;
              }

              .cardNumber {
                font-size: 6px;
              }
            }

            .copy-wrapper {
              right: 20px;
              bottom: 20px;
              font-size: 8px;

              .copy {
                width: 8px;
                height: 8px;
              }
            }
          }

          .share-link {
            margin: 8px 0 8px auto;
            display: flex;
            justify-content: center;
            width: 50%;
            opacity: 0.7;
            color: #183177;
            font-size: 10px;
            letter-spacing: -0.05px;
          }
        }
      }

      &.section-gift {
        .body {
          height: 160px;
          background: #032852 url("../../assets/images/section-banner-gift.png") no-repeat right;
          background-size: cover;
          display: flex;
          padding: 25px 0;

          .gift-box {
            margin-top: auto;
            margin-bottom: auto;
            padding-left: 30px;

            .box {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }

            .title {
              color: $white;
              font-size: 14px;
              font-weight: 700;
            }

            .prize {
              text-shadow: 0 0 1px #ff8800;
              color: $secondary-color;
              font-size: 55px;
              font-weight: 700;
              line-height: 1;
              margin-bottom: 10px;
            }

            .button {
              cursor: pointer;
              box-shadow: 0 0 2px #3aff00;
              border-radius: 4px;
              background-image: linear-gradient(180deg, #3ff169 0%, #31aa47 100%);
              color: #011738;
              font-size: 11px;
              font-weight: 700;
              letter-spacing: -0.55px;
              padding: 5px 15px;
            }
          }
        }
      }

      &.section-voting,
      &.section-fener {
        .body {
          text-align: center;

          img {
            padding: 10px;
            width: 50%;
          }
        }
      }

      &.section-card {
        .campaign-wrapper {
          display: flex;
          justify-content: space-evenly;

          #campaign-card {
            margin: 10px 0 10px 10px;
          }

          .more {
            cursor: pointer;
            margin-top: auto;
            margin-bottom: auto;
            text-align: center;

            .more-text {
              display: block;
              color: #023385;
              font-size: 10px;
              font-weight: 700;
            }

            .more-image {
              width: 18px;
            }
          }
        }
      }

      &.section-fener {
        #personality {
          .personality-title {
            margin-top: 12px;
          }

          .button-wrapper {
            justify-content: center;

            .personality-button {
              max-width: 140px;
            }
          }
        }
      }

      &.section-mail {
        .body {
          background: url("../../assets/images/section-mail-background.png") no-repeat center;
          background-size: cover;
          position: relative;
          display: flex;
          justify-content: center;
          padding-top: 30px;
          padding-bottom: 50px;

          .content {
            display: flex;

            .text-wrapper {
              display: flex;
              flex-direction: column;
              margin-right: 20px;
              padding-top: 15px;

              .text-upper {
                color: #f9f9f9;
                font-size: 11px;
                line-height: 13px;
                margin-bottom: 5px;
              }

              .text-lower {
                color: #f9f9f9;
                font-weight: bold;
                font-size: 14px;
                line-height: 17px;

                .at {
                  color: $secondary-color;
                }
              }
            }

            .image {
              width: 72px;
              height: 72px;
            }
          }

          .soon {
            position: absolute;
            bottom: 20px;
            left: 0;
            width: fit-content;
            background: #f9f9f9;
            clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
            color: $primary-color;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            padding: 5px 35px 5px 30px;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .page-content {
      padding-top: 10px;
      padding-bottom: 34px;

      .section {
        margin-bottom: 15px;
        box-shadow: 0 0 6px rgba(123, 155, 209, 0.4);
        border-radius: 9px;

        .heading {
          img {
            min-width: 67px;
            width: 67px;
            margin-right: 15px;
          }

          .info {
            .title {
              font-size: 17px;
              letter-spacing: -0.85px;
            }

            .text {
              font-size: 15px;
              letter-spacing: -0.3px;
            }
          }
        }

        &.section-share {
          .body {
            .image-left {
              padding: 20px;
            }

            #personality,
            #personality-small {
              border-top-left-radius: 9px;
              border-bottom-left-radius: 9px;
            }

            #user-fan-card {
              padding: 20px;

              .user-info-wrapper {
                left: 35px;
                bottom: 35px;

                .fullName {
                  font-size: 8px;
                }

                .cardNumber {
                  font-size: 9px;
                }
              }

              .copy-wrapper {
                right: 35px;
                bottom: 35px;
                font-size: 10px;

                .copy {
                  width: 10px;
                  height: 10px;
                }
              }
            }

            .share-link {
              margin: 10px 0 10px auto;
              font-size: 13px;
              letter-spacing: -0.07px;
            }
          }
        }

        &.section-gift {
          .body {
            height: 217px;
            background: #032852 url("../../assets/images/section-banner-gift.png") no-repeat right;
            background-size: cover;
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;

            .gift-box {
              padding-left: 55px;

              .title {
                font-size: 22px;
                line-height: 26px;
              }

              .prize {
                font-size: 63px;
                margin-bottom: 15px;
              }

              .button {
                font-size: 12px;
                letter-spacing: -0.6px;
                padding: 5px 20px;
              }
            }
          }
        }

        &.section-card {
          .campaign-wrapper {
            #campaign-card {
              margin: 12px 0 15px 15px;
              height: 165px;

              .body {
                width: 115px;
                max-width: 115px;

                .content {
                  padding: 10px 14px;

                  .card-header {
                    transform: translateX(-20px);
                    width: 110px;
                    font-size: 11px;
                  }

                  .card-name {
                    font-size: 15px;
                  }

                  .card-button {
                    height: 26px;
                    font-size: 11px;
                    letter-spacing: -0.55px;
                  }
                }
              }
            }

            .more {
              .more-text {
                font-size: 11px;
              }

              .more-image {
                width: 21px;
              }
            }
          }
        }

        &.section-fener {
          #personality {
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;
          }
        }

        &.section-mail {
          .body {
            padding-top: 40px;
            padding-bottom: 70px;

            .content {
              .text-wrapper {
                margin-right: 50px;

                .text-upper {
                  font-size: 18px;
                  line-height: 21px;
                }

                .text-lower {
                  font-size: 24px;
                  line-height: 28px;
                }
              }

              .image {
                width: 127px;
                height: 127px;
              }
            }

            .soon {
              bottom: 30px;
              font-size: 16px;
              line-height: 19px;
              padding: 5px 68px 5px 60px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .page-content {
      padding-top: 28px;
      padding-bottom: 100px;

      #user-fan-card {
        .copy-wrapper {
          .copy {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .page-content {
      padding-top: 35px;
      padding-bottom: 80px;

      .section {
        margin-bottom: 17px;

        &.section-share {
          .body {
            #user-fan-card {
              .user-info-wrapper {
                left: 40px;
                bottom: 40px;

                .fullName {
                  font-size: 8px;
                }

                .cardNumber {
                  font-size: 9px;
                }
              }

              .copy-wrapper {
                right: 40px;
                bottom: 40px;

                .copy {
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }
        }

        &.section-card {
          .campaign-wrapper {
            #campaign-card {
              margin: 15px 0 20px 20px;
              height: 186px;

              .body {
                width: 128px;
                max-width: 128px;

                .content {
                  padding: 20px 14px 10px 14px;
                }
              }
            }
          }
        }

        &.section-fener {
          #personality {
            .personality-title {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
