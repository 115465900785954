@import "../../variables";

#about-us-page {
    margin-top: auto;
    .page-content {
        align-items: center;
        text-align: center;
        .section-about {
            .text {
                color: $primary-color;
                font-size: 17px;
                line-height: normal;
                letter-spacing: -0.85px;
            }
        }
        .body {
            .img-about {
                justify-content: center;
                padding-top: 30px;
            }
        }
    }

    @media (min-width: 768px) {
        .page-content {
            .section-about {
                max-width: 75%;
                .text {
                    font-size: 24px;
                    font-weight: 400;
                    letter-spacing: -1.2px;
                }
            }
            .body {
                .img-about {
                    padding-top: 150px;
                }
            }
        }
    }

    @media (min-width: 1024px) {
        .page-content {
            .section-about {
                max-width: 900px;
                .text {
                    font-size: 21px;
                    font-weight: 400;
                    letter-spacing: -1.05px;
                }
            }
            .body {
                .img-about {
                    padding-top: 10px;
                }
            }
        }
    }

    @media (min-width: 1280px) { 
        .page-content {
            .section-about {
                .text {
                    font-size: 23px;
                    font-weight: 400;
                    letter-spacing: -1.15px;
                }
            }
            .body {
                .img-about {
                    padding-top: 40px;
                }
            }
        }
    }
}
