@import "../../../variables";

#auth-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  margin: auto auto 0;

  .icon-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px 8px 0 0;
    width: 200px;
    height: 40px;
  }

  .branding {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $secondary-color;
    border-bottom: 1px solid $secondary-color;
    font-size: 13px;
    color: $white;

    .brand {
      margin-left: 4px;
      font-weight: bold;
    }
  }
}
