@import "../../../variables";

#privileges {
  display: flex;
  justify-content: center;
  width: 100%;
  background: rgba(225, 225, 225, 0.1);
  padding: 4px 0;
  margin-top: auto;
  margin-bottom: 12px;

  .box-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    color: $white;

    .info {
      font-size: 10px;
      line-height: 12px;
    }

    .box {
      border: 1px solid $white;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 4px;
      display: flex;
      align-items: center;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 2px;
      }

      .text {
        font-weight: 500;
        font-size: 7px;
        line-height: 8px;
      }

      &:last-child {
        border: 1px solid #ffffff66;
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .icon {
          margin-right: 0;
        }
      }
    }
  }

  @media (min-width: 992px) {
    padding: 16px 0;

    .box-wrapper {

      .info {
        font-size: 18px;
        line-height: 21px;
      }

      .box {
        padding: 5px;

        .icon {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }

        .text {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}
