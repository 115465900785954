@import "../../variables";

#personality-small {
  background: url(../../assets/images/personality-small-bg.png) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 115px;

  .personality-heading {
    cursor: pointer;
    background-color: $primary-color;
    color: $secondary-color;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    text-align: center;
    padding: 4px 0;
    margin-top: 10px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .personality-title {
    cursor: pointer;
    color: $primary-color;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    text-align: center;
    margin: 4px 0;
  }

  .personality-description {
    color: $primary-color;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    margin: auto 0;
  }

  .render-again {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 10px;

    .upload-wrapper {
      $avatar-width: 65px;
      $avatar-height: 70px;
      position: relative;
      min-width: $avatar-width;
      height: $avatar-height;

      .avatar-uploader,
      .placeholder,
      .avatar, {
        width: $avatar-width;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .placeholder,
      .avatar {
        height: $avatar-height;
        border-radius: 2px 2px 8px 8px;
      }

      .image-spinner {
        background-color: #F9F9F9;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .upload-tip {
        position: absolute;
        top: 0;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;

        .content {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          background: rgba(0, 45, 114, 0.75);
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 4px 0;

          .text {
            font-weight: 500;
            font-size: 6px;
            line-height: 7px;
            color: #FFFFFF;
          }
        }
      }

      .delete-avatar {
        cursor: pointer;
        position: absolute;
        color: $white;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
      }
    }

    .personality-title {
      text-align: left;
    }

    .personality-button {
      font-size: 9px;
    }

    .button-solid {
      flex: 2;
    }
  }

  .personality-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-weight: bold;
    font-size: 10px;
    padding: 0;
    margin: 4px 4px 8px;
    max-height: 22px;

    &.fix-width {
      width: 100px;
      margin-left: auto;
      margin-right: auto;
    }

    .button-image {
      width: 12px !important;
      height: 12px !important;
      padding: 0 !important;
      margin-right: 3px !important;

      &.continue {
        margin-left: 3px !important;
        margin-right: 0 !important;
      }
    }
  }

  .button-solid {
    flex: 1;
    background-color: $primary-color;
    color: $white;
  }

  .button-outline {
    flex: 1;
    background-color: transparent;
    color: $primary-color;
    border: 1px solid $primary-color;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    min-width: 100px;
  }

  @media (min-width: 768px) {
    min-height: 190px;

    .personality-heading {
      font-size: 14px;
      line-height: 17px;
      padding: 8px 0;
      margin-top: 25px;
    }

    .personality-title {
      font-size: 18px;
      line-height: 21px;
      margin: 10px 0;
    }

    .personality-description {
      font-size: 16px;
      line-height: 19px;
      padding: 0 22px;
    }

    .render-again {
      display: flex;
      margin-top: auto;
      margin-bottom: auto;
      padding-left: 20px;

      .upload-wrapper {
        $avatar-width: 115px;
        $avatar-height: 125px;
        min-width: $avatar-width;
        height: $avatar-height;

        .avatar-uploader,
        .placeholder,
        .avatar, {
          width: $avatar-width;
        }

        .placeholder,
        .avatar {
          height: $avatar-height;
          border-radius: 4px 4px 16px 16px;
        }

        .upload-tip {
          height: 75px;

          .content {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 0;

            .text {
              font-size: 11px;
              line-height: 24px;
            }
          }
        }
      }

      .personality-button {
        font-size: 14px;
      }
    }

    .personality-button {
      font-size: 14px;
      margin-bottom: 10px;
      max-height: 36px;

      &.fix-width {
        width: 135px;
        margin-left: auto;
        margin-right: auto;
      }

      .button-image {
        width: 20px !important;
        height: 20px !important;
        margin-right: 3px !important;
      }
    }

    .button-wrapper {
      min-width: 150px;
    }
  }

  @media (min-width: 1280px) {
    min-height: 210px;
  }
}
