@import "../../../../variables";

.consent-approval-modal {
  width: 245px !important;

  .ant-modal-content {
    border-radius: 9px;

    .ant-modal-body {
      padding: 0;

      .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-bottom: 16px;
        $logoDim: 48px;

        .header {
          position: relative;
          min-height: 40px;
          width: 100%;
          border-top-left-radius: 9px;
          border-top-right-radius: 9px;
          background-color: $secondary-dark-color;
          padding: 16px 32px 20px 32px;

          .logo {
            width: $logoDim;
            height: $logoDim;
            position: absolute;
            bottom: -$logoDim / 2;
            left: 50%;
            transform: translate(-50%, 0%);
            background-color: $white;
            padding: 8px;
            border-radius: 100px;
          }
        }

        .title {
          margin-top: 10px + $logoDim / 2;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #474747;
        }

        .text {
          color: #4a4a4a;
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 16px;
          padding: 0 16px;
        }

        .button-wrapper {
          display: flex;

          .button {
            min-width: 80px;
            background-color: $primary-color;
            border: none;
            color: $white;
            height: 26px;
            border-radius: 8px;
            font-size: 11px;
            font-weight: 500;

            &.cancel {
              border: 1px solid $primary-color;
              color: $primary-color;
              background-color: transparent;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    width: 305px !important;

    .ant-modal-content {

      .ant-modal-body {

        .body {
          padding-bottom: 20px;
          $logoDim: 68px;

          .header {
            padding: 20px 50px 30px 50px;

            .logo {
              width: $logoDim;
              height: $logoDim;
              position: absolute;
              bottom: -$logoDim / 2;
              left: 50%;
              transform: translate(-50%, 0%);
            }
          }

          .text {
            font-size: 12px;
            line-height: 17px;
            margin-bottom: 20px;
            padding: 0 20px;
          }

          .button-wrapper {

            .button {
              min-width: 100px;
              height: 32px;
              font-size: 13px;

              &.cancel {
                margin-right: 15px;
              }
            }

            .ant-spin {
              height: 32px;
            }
          }
        }
      }
    }
  }
}
