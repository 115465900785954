@import "../../../variables";

.poll-modal {
  width: 345px !important;

  .ant-modal-content {
    border-radius: 16px;
    background: url("../../../assets/images/voting-bg-popup.png") no-repeat bottom;
    background-size: cover;

    .ant-modal-body {
      padding: 0;

      .poll-body {

        .ant-tabs {

          .ant-tabs-nav {
            margin-bottom: 0;

            .ant-tabs-nav-wrap {
              display: block;

              .ant-tabs-nav-list {
                background: #8eacd440;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;

                .ant-tabs-tab {
                  display: flex;
                  justify-content: center;
                  flex-grow: 1;
                  opacity: 0.25;
                  color: $primary-color;
                  border-top-left-radius: 16px;
                  border-top-right-radius: 16px;
                  border: none;
                  margin: 0;

                  &.ant-tabs-tab-active {
                    background: $white;
                    opacity: 1;
                  }
                }
              }
            }
          }

          .ant-tabs-content-holder {
            padding-right: 8px; // for scrollbar
            padding-bottom: 8px; // for scrollbar

            .poll-name {
              background: rgba(195, 209, 234, 0.25);
              border-radius: 6px;
              padding: 2px 4px;
              width: fit-content;
              font-weight: 500;
              font-size: 10px;
              line-height: 14px;
            }

            .poll-question {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              margin-top: 10px;
              margin-bottom: 22px;
            }

            .poll-option {
              cursor: pointer;
              display: flex;
              align-items: center;
              padding: 8px;
              border: 1px solid #C3D1EA;
              border-radius: 12px;
              margin-top: 8px;

              .option {
                min-width: 16px;
                max-width: 16px;
                height: 16px;
                border-radius: 100px;
                background: #C3D1EA;
                margin-right: 5px;

                &.selected {
                  background: $white;
                  border: 5px solid $primary-color;
                }
              }
            }
          }
        }
      }
    }
  }

  * {
    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #C3D1EA transparent;
  }

  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #C3D1EA;
    border-radius: 8px;
    border: none;
  }
}
