#login-subscription-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #fan-card {
    margin-top: 25px;
  }

  .link-button {
    font-size: 12px;
    font-weight: 700;

    span {
      text-decoration: underline;
    }
  }

  @media (min-width: 768px) {
    #fan-card {
      margin-top: 55px;
    }

    .link-button {
      font-size: 15px;
    }
  }

  @media (min-width: 1024px) {
    #fan-card {
      margin-top: 15px;
    }

    .link-button {
      font-size: 12px;
    }
  }

  @media (min-width: 1280px) {
    #fan-card {
      margin-top: 25px;
    }

    .link-button {
      font-size: 15px;
    }
  }
}
