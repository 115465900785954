@import "../../../variables";

#auth-layout {
  min-height: 100vh;
  width: 100%;
  background: url(https://s3-eu-west-1.amazonaws.com/static.4p/fenerbahce-landing-bg.png) no-repeat center;
  background-size: cover;

  .ant-layout {
    min-height: 100vh;
    width: 100%;

    .ant-layout-content {
      min-height: 100vh;
      width: 100%;

      .container {

        .page-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;

          .ant-form-item {

            .form-input,
            .ant-select-selector {
              display: flex;
              align-items: center;
              height: 42px;
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
              border-radius: 7px;
              border: 1px solid lightgray;
              background-color: #ffffff;

              &.border-primary {
                border: 1px solid $primary-color;
              }

              &.no-error-border {
                border: 1px solid lightgray !important;
              }
            }

            .text-area {
              display: flex;
              align-items: center;
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
              border-radius: 7px;
              border: 1px solid lightgray;
              background-color: #ffffff;
            }

            .ant-checkbox {
              width: 20px;
              height: 20px;
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
              border-radius: 4px;
              border: 1px solid lightgray;
              background-color: #ffffff;

              .ant-checkbox-input,
              .ant-checkbox-inner {
                border: none;
                width: 18px;
                height: 18px;
              }
            }

            &.ant-form-item-has-error {
              .form-input,
              .text-area,
              .ant-select-selector,
              .ant-checkbox, {
                border: 1px solid red;
              }
            }
          }
        }
      }
    }
  }
}
