@import "../../../variables";

.generic-modal {
  width: 245px !important;

  .ant-modal-content {
    border-radius: 9px;

    .ant-modal-body {
      padding: 0;

      .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-bottom: 16px;
        $logoDim: 48px;

        .header {
          position: relative;
          min-height: 80px;
          width: 100%;
          border-top-left-radius: 9px;
          border-top-right-radius: 9px;
          background-color: $secondary-dark-color;
          padding: 16px 32px 32px 32px;

          .title {
            color: $primary-color;
            font-size: 12px;
            letter-spacing: -0.6px;
          }

          .logo {
            width: $logoDim;
            height: $logoDim;
            position: absolute;
            bottom: -$logoDim / 2;
            left: 50%;
            transform: translate(-50%, 0%);
            background-color: $white;
            padding: 8px;
            border-radius: 100px;
          }
        }

        .text {
          color: #4a4a4a;
          font-size: 10px;
          line-height: 14px;
          margin-top: 10px + $logoDim / 2;
          margin-bottom: 16px;
          padding: 0 16px;
        }

        .button {
          background-color: #82a0d8;
          border: none;
          color: $white;
          height: 26px;
          border-radius: 8px;
          font-size: 11px;
          font-weight: 500;
        }

        .button-secondary {
          border: none;
          color: #82a0d8;
          font-size: 11px;
          font-weight: 500;
          margin-top: 10px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    width: 305px !important;

    .ant-modal-content {

      .ant-modal-body {

        .body {
          padding-bottom: 20px;
          $logoDim: 68px;

          .header {
            padding: 20px 50px 40px 50px;

            .title {
              font-size: 14px;
              letter-spacing: -0.7px;
            }

            .logo {
              width: $logoDim;
              height: $logoDim;
              position: absolute;
              bottom: -$logoDim / 2;
              left: 50%;
              transform: translate(-50%, 0%);
            }
          }

          .text {
            font-size: 12px;
            line-height: 17px;
            margin-top: 10px + $logoDim / 2;
            margin-bottom: 20px;
            padding: 0 20px;
          }

          .button {
            height: 32px;
            font-size: 13px;
          }

          .button-secondary {
            font-size: 13px;
          }
        }
      }
    }
  }
}
