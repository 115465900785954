@import "../../../variables";

#campaign-page {

  .page-content {
    padding: 40px;

    .campaign {
      display: flex;
      flex-direction: column;
      align-items: center;
      $size: 166px;

      .logo {
        width: $size;
      }

      .image {
        margin-top: 20px;
        margin-bottom: 5px;
        width: $size;
        height: $size;
      }

      .title {
        color: #00307e;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.8px;
        margin-bottom: 0;
      }

      .description {
        text-align: center;
        color: #183788;
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      .tckn-required {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .tckn-error {
          color: #ff0303;
          font-size: 11px;
          font-style: italic;
        }

        .tckn-button {
          border-radius: 6px;
          background-color: #065ae194;
          color: $white;
          font-size: 14px;
          padding: 6px 15px;
          margin-top: 8px;
        }
      }

      .tckn-required-placeholder {
        width: 100%;
        margin-bottom: 40px;
      }

      .info-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .terms-title {
          display: flex;
          justify-content: center;
          color: #183788;
          font-size: 15px;
        }

        .terms {
          padding-left: 15px;

          .term {
            color: #707070;
            font-size: 13px;
          }
        }

        .how-to-use-title {
          color: #183788;
          font-size: 15px;
        }

        .how-to-use {
          color: #707070;
          font-size: 13px;
        }
      }
    }
  }

  @media (min-width: 768px) {

    .page-content {
      width: 400px !important;
      padding: 40px 0;

      .campaign {
        $size: 235px;

        .logo {
          width: $size;
        }

        .image {
          margin-top: 20px;
          margin-bottom: 5px;
          width: $size;
          height: $size;
        }

        .title {
          font-size: 24px;
          letter-spacing: -1.05px;
        }

        .description {
          font-size: 20px;
          margin-top: 10px;
          margin-bottom: 20px;
        }

        .tckn-required {
          margin-bottom: 25px;

          .tckn-error {
            font-size: 14px;
          }

          .tckn-button {
            font-size: 20px;
            padding: 10px 15px;
            margin-top: 12px;
          }
        }

        .tckn-required-placeholder {
          margin-bottom: 30px;
        }

        .info-wrapper {

          .terms-title {
            font-size: 19px;
          }

          .terms {
            padding-left: 15px;

            .term {
              font-size: 16px;
            }
          }

          .how-to-use-title {
            font-size: 19px;
          }

          .how-to-use {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {

    .page-content {
      width: 400px !important;
      padding: 30px 0;

      .campaign {
        $size: 166px;

        .logo {
          width: $size;
        }

        .image {
          margin-top: 20px;
          margin-bottom: 5px;
          width: $size;
          height: $size;
        }

        .title {
          font-size: 20px;
          letter-spacing: -0.8px;
        }

        .description {
          font-size: 15px;
          margin-top: 10px;
          margin-bottom: 20px;
        }

        .tckn-required {
          margin-bottom: 10px;

          .tckn-error {
            font-size: 12px;
          }

          .tckn-button {
            font-size: 14px;
            padding: 5px 15px;
            margin-top: 8px;
          }
        }

        .tckn-required-placeholder {
          margin-bottom: 20px;
        }

        .info-wrapper {

          .terms-title {
            font-size: 15px;
          }

          .terms {
            padding-left: 15px;

            .term {
              font-size: 13px;
            }
          }

          .how-to-use-title {
            font-size: 15px;
          }

          .how-to-use {
            font-size: 13px;
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {

    .page-content {
      width: 470px !important;
      padding: 30px 0;

      .campaign {
        $size: 215px;

        .logo {
          width: $size;
        }

        .image {
          margin-top: 20px;
          margin-bottom: 5px;
          width: $size;
          height: $size;
        }

        .title {
          font-size: 24px;
          letter-spacing: -1px;
        }

        .description {
          font-size: 19px;
          margin-top: 10px;
          margin-bottom: 20px;
        }

        .tckn-required {
          margin-bottom: 25px;

          .tckn-error {
            font-size: 13px;
          }

          .tckn-button {
            font-size: 16px;
            padding: 8px 15px;
            margin-top: 10px;
          }
        }

        .tckn-required-placeholder {
          margin-bottom: 30px;
        }

        .info-wrapper {

          .terms-title {
            font-size: 18px;
          }

          .terms {
            padding-left: 15px;

            .term {
              font-size: 15px;
            }
          }

          .how-to-use-title {
            font-size: 18px;
          }

          .how-to-use {
            font-size: 15px;
          }
        }
      }
    }
  }
}
