@import "../../../../../variables";

#signup-msisdn-form {
  margin: auto;
  width: 310px;
  color: $gray;
  text-align: center;

  .ant-form-item {
    margin: 10px auto 5px auto;

    .ant-form-item-explain {
      min-height: 0;
    }
  }

  .info {
    font-size: 10px;
    text-align: justify;
  }

  .helper-1 {
    opacity: 0.75;
    font-size: 8px;
    font-style: italic;
    letter-spacing: normal;
    line-height: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .helper-2 {
    letter-spacing: normal;
    font-size: 8px;
    margin-bottom: 20px;
  }

  .payment-wrapper {
    margin-bottom: 20px;

    img {
      max-height: 30px;
    }
  }

  @media (min-width: 768px) {
    .helper-1 {
      font-size: 10px;
      margin-top: 20px;
      margin-bottom: 28px;
    }
  }

  @media (min-width: 1024px) {

    .helper-1 {
      transform: translateX(-10%);
      width: 130%;
      margin-bottom: 10px;
    }

    .helper-2 {
      transform: translateX(-10%);
      width: 130%;
      margin-bottom: 10px;
    }
  }
}
