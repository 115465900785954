@import "../../../../variables";

#poll-question-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 15px 15px 25px;
  min-height: 460px;
  max-height: 460px;
  overflow-x: hidden;
  overflow-y: scroll;

  .poll-question-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: $primary-color;

    .free-text {
      border: 2px solid #C3D1EA;
      border-radius: 16px;
      padding: 12px;
      margin-top: 8px;
    }

    .send {
      border: none;
      border-radius: 8px;
      margin-top: 32px;
      color: $white;

      &:disabled {
        background: $primary-color;
        opacity: 0.25;
      }
    }
  }
}
