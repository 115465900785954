@import "~bootstrap/dist/css/bootstrap-grid.min.css";

html,
body,
#root {
  overflow: auto;
}

body {
  margin: 0;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@font-face {
  font-family: Rubik;
  src: url("/assets/fonts/Rubik/Rubik-Regular.woff");
  font-weight: 400;
}

@font-face {
  font-family: Rubik;
  src: url("/assets/fonts/Rubik/Rubik-Medium.woff");
  font-weight: 500;
}

@font-face {
  font-family: Rubik;
  src: url("/assets/fonts/Rubik/Rubik-Bold.woff");
  font-weight: 700;
}
