@import "../../../variables";

#auth-header-mobile {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;

  .auth-header-mobile-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;

    .page-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: $white;
    }

    .faq {
      width: 28px;
      height: 28px;
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
}