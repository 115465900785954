@import "../../../variables";

#test-cover {
  display: flex;
  flex-direction: column;

  .cover {
    position: relative;
    background: #F9F9F9;
    border-radius: 8px;
    padding: 15px;
    max-width: 550px;

    &.start {
      margin-top: 100px;

      .title {
        margin-top: 75px;
      }

      .line {
        border: 1px solid #002d7240;
      }

      .cover-image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        height: 250px;
        border-radius: 1000px;
      }
    }

    &.finish {
      margin-top: 160px;
      padding-bottom: 5px;

      .title {
        margin-top: 120px;
      }

      .line {
        border: 1px solid $primary-color;
      }

      .sub-info {
        color: $primary-color;
        font-size: 14px;
        line-height: 24px;
        margin: 10px 0;
      }

      .social-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          outline: none !important;
        }

        .social {
          cursor: pointer;
        }

        .download {
          cursor: pointer;
          margin-left: 10px;
          margin-bottom: 8px;
        }
      }

      .delete-avatar {
        cursor: pointer;
        position: absolute;
        color: $white;
        top: -135px;
        left: 50%;
        transform: translate(80px, 0);
        width: 32px;
        height: 32px;
      }
    }

    .ant-upload {

      img {
        cursor: pointer;
      }
    }

    .avatar-uploader,
    .placeholder,
    .avatar, {
      width: 235px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .placeholder,
    .avatar {
      height: 255px;
      border-radius: 8px 8px 32px 32px;
    }

    .image-spinner {
      background-color: #F9F9F9;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .upload-tip {
      position: absolute;
      top: 0;
      height: 127.5px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;

      .content {
        border-bottom-left-radius: 32px;
        border-bottom-right-radius: 32px;
        background: rgba(0, 45, 114, 0.75);
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 4px 0;

        .icon {
          margin-right: 10px;
        }

        .text {
          font-weight: 500;
          font-size: 11px;
          line-height: 24px;
          color: #FFFFFF;
        }
      }
    }

    .title {
      text-align: center;
      color: $primary-color;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 10px;
    }

    .info {
      font-size: 16px;
      line-height: 24px;
      color: #808080;
      margin-bottom: 0;
      text-align: center;
    }
  }

  .cover-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary-color;
    border-radius: 4px;
    color: $primary-color;
    padding: 0 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 40px auto 0;
    min-height: 40px;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .personality-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      padding: 0;
      margin: 4px 4px 8px;
      max-height: 36px;

      &.fix-width {
        width: 100px;
        margin-left: auto;
        margin-right: auto;
      }

      .button-image {
        width: 18px !important;
        height: 18px !important;
        padding: 0 !important;
        margin-right: 3px !important;

        &.continue {
          margin-left: 3px !important;
          margin-right: 0 !important;
        }
      }
    }

    .button-solid {
      flex: 1;
      background-color: $secondary-color;
      color: $primary-color;
    }

    .button-outline {
      flex: 1;
      background-color: transparent;
      color: $secondary-color;
      border: 1px solid $secondary-color;
    }
  }

  @media (min-width: 768px) {

    .cover {
      padding: 40px;

      &.start {
        .title {
          margin-top: 50px;
        }
      }

      &.finish {
        padding-bottom: 10px;

        &.large-screen {
          max-width: 1000px;
          background: transparent;
          margin-top: 0;
          display: flex;

          .upload-wrapper {
            position: relative;
            min-width: 425px;

            .avatar-uploader,
            .avatar, {
              width: 425px;
              height: 425px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .upload-tip {
              position: absolute;
              height: 425px;
              display: flex;
              justify-content: center;
              align-items: flex-end;
              width: 100%;

              .text {
                font-size: 20px;
                line-height: 32px;
              }
            }
          }

          .large-screen-content {
            margin-left: 15px;
            min-width: 500px;
            background: #F9F9F9;
            border-radius: 8px;
            padding: 15px 0;

            .title {
              background: #002D72;
              border-radius: 8px 0 0 8px;
              font-weight: bold;
              font-size: 22px;
              line-height: 28px;
              color: #FFFFFF;
              margin-top: 0;
              margin-bottom: 15px;
              margin-left: 25px;
              padding: 8px 0;
            }

            .inner-box {
              display: flex;
              flex-direction: column;
              padding: 0 50px;

              .info {
                text-align: left;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #808080;
              }

              .sub-info {
                text-align: left;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #002D72;
              }

              .button-wrapper {
                width: 75%;
                margin: 10px auto 0;

                .button-solid {
                  flex: 1;
                  border: 1px solid $primary-color;
                  background-color: $primary-color;
                  color: $secondary-color;
                }

                .button-outline {
                  color: $primary-color;
                  border: 1px solid $primary-color;
                }
              }
            }
          }
        }

        .delete-avatar {
          top: -132px;
          right: 150px;
        }
      }
    }
  }

  @media (min-width: 1200px) {

    .cover {

      &.finish {

        .delete-avatar {
          left: 90%;
          transform: none;
          top: -40px;
        }
      }
    }
  }
}
