@import "../../variables";

#app-footer {
  position: relative;
  background-color: #00307e;
  margin-top: auto;
  display: flex;
  justify-content: center;

  .branding {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: $white;

    .brand {
      margin-left: 4px;
      font-weight: bold;
    }
  }

  &.mobile {
    flex-direction: column;

    .logo {
      position: absolute;
      $dim: 54px;
      width: $dim;
      height: $dim;
      top: 50%;
      left: 10%;
      transform: translate(0%, -50%);
    }

    .wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;

      &.upper {
        border-bottom: 1px solid $secondary-color;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &.lower {
        padding-top: 3px;
      }

      .icon {
        margin-left: 15px;
      }
    }
  }

  &.desktop {
    height: 57px;

    .logo {
      position: absolute;
      $dim: 54px;
      width: $dim;
      height: $dim;
      top: -$dim / 2;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    .wrapper {
      width: 500px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        margin-left: 8px;
      }
    }

    @media (min-width: 768px) {
      height: 76px;

      .logo {
        $dim: 70px;
        width: $dim;
        height: $dim;
        top: -$dim / 2;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }
  }
}
