@import "../../../variables";

#button-primary {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  font-weight: 500;
  color: $white;
  font-size: 17px;
  border-radius: 11px;
  padding-left: 35px;
  padding-right: 35px;

  .arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 16px;
  }

  &:disabled {
    background-color: $primary-color;
    opacity: 0.6;
  }

  &.button-yellow {
    background-color: $secondary-color;
    color: $primary-color;

    &:disabled {
      background-color: $secondary-color;
      opacity: 0.6;
    }
  }

  &.button-dark-yellow {
    background-color: $secondary-dark-color;
    color: $primary-color;

    &:disabled {
      background-color: $secondary-dark-color;
      opacity: 0.6;
    }
  }

  @media (min-width: 768px) {
    height: 55px;
    font-size: 21px;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-width: 1024px) {
    height: 47px;
    font-size: 19px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 1280px) {
    height: 56px;
    font-size: 22px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
