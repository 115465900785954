@import "../../../../../variables";

#signup-info-form {
  width: 300px;

  .info {
    text-align: center;
    margin-bottom: 15px;
  }

  .checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 56px;

    .checkbox-text {
      color: $gray;
      font-size: 10px;
      font-weight: 400;
      line-height: 32px;

      .link {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 768px) {
    width: 335px;

    .checkbox-wrapper {

      .checkbox-text {
        font-size: 12px;
      }
    }
  }

  @media (min-width: 1024px) {
    width: 300px;

    .checkbox-wrapper {

      .checkbox-text {
        font-size: 10px;
      }
    }
  }

  @media (min-width: 1280px) {
    width: 315px;
  }
}
