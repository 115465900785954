@import "../../../../../variables";

.modal-contract {

  .ant-modal-content {
    border-radius: 22px;

    .ant-modal-body {
      padding-right: 5px;

      .modal-content {
        max-height: 50vh;
        overflow-y: auto;
        margin-top: 20px;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
          width: 20px;
        }

        &::-webkit-scrollbar:horizontal {
          height: 20px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          border: 7px solid white; /* should match background, can't be transparent */
          background-color: #0e293c2e;
        }

        .text {
          color: $gray;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }
  }

  @media (min-width: 400px) {

    .ant-modal-content {

      .ant-modal-body {
        padding-right: 24px;
      }
    }
  }

  @media (min-height: 700px) {

    .ant-modal-content {

      .ant-modal-body {

        .modal-content {
          max-height: 65vh;
        }
      }
    }
  }
}
