@import "../../../variables";

#failed-subscription {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-fail {
    width: 86px;
    margin: 30px 0 60px 0;
  }

  .img-fail-icon {
    font-size: 120px;
    color: $white;
    margin: 60px 0;
  }

  .info {
    text-align: center;
    color: $white;
    font-size: 12px;

    &.error {
      color: $white;
      margin-bottom: 40px;
    }
  }

  #button-primary {
    border: 1px solid $secondary-dark-color;
  }

  .or {
    color: $white;
    font-size: 12px;
    font-weight: 700;
    margin: 10px 0;
  }

  .redirect-credit-card {
    border: none !important;
    background-color: $secondary-dark-color;
    color: $primary-color;
  }

  .link-button {
    font-size: 12px;
    font-weight: 700;
    color: $white;

    span {
      text-decoration: underline;
    }
  }

  @media (min-width: 768px) {

    .info {
      font-size: 15px;
    }

    .or {
      font-size: 15px;
      margin: 15px 0;
    }

    .link-button {
      font-size: 15px;
    }
  }

  @media (min-width: 1024px) {

    .info {
      font-size: 12px;
    }

    .or {
      font-size: 12px;
    }

    .link-button {
      font-size: 12px;
    }
  }

  @media (min-width: 1280px) {

    .info {
      font-size: 15px;
    }

    .or {
      font-size: 15px;
    }

    .link-button {
      font-size: 15px;
    }
  }
}
