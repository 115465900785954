@import "../../../variables";

#voting-banner {

  .voting-body {
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    background: url("../../../assets/images/voting-bg-banner.png") no-repeat bottom;

    .title {
      width: fit-content;
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: rgba(0, 45, 114, 0.75);
      padding: 2px 8px;
      background: rgba(195, 209, 234, 0.25);
      border-radius: 6px;
    }

    .question {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: $primary-color;
      margin: 10px 0;
    }

    .button {
      border-radius: 8px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
    }

    .button-wrapper {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 8px;

      .button {
        width: 100px;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 10px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .answered-vote {
      display: flex;
      align-items: center;
      margin-top: 5px;

      .counter {
        padding: 4px 8px;
        border: 1px solid #C3D1EA;
        border-radius: 6px;
        font-weight: bold;
        font-size: 8px;
        line-height: 14px;
        color: $primary-color;
        margin-right: 10px;

        .count {
          display: inline-flex;
          padding: 2px;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          background: $secondary-color;
          width: fit-content;
        }
      }
    }
  }

  @media (min-width: 768px) {

    .voting-body {
      padding: 40px 32px;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;

      .title {
        font-size: 14px;
      }

      .question {
        font-size: 24px;
        line-height: 24px;
      }

      .button-wrapper {
        margin-top: 14px;

        .button {
          width: 120px;
          height: 40px;
          font-size: 14px;
          line-height: 17px;
        }
      }

      .answered-vote {
        margin-top: 20px;

        .counter {
          padding: 8px 16px;
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
  }
}
