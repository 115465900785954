@import "../../../variables";

#mobile-payment-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #privileges {
    display: none;
  }

  .page-content {
    color: $white;

    .sms-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 330px;

      #welcome-text {
        text-align: left;
      }

      .info {
        margin: 40px 0;
      }
    }

    .link-button {
      font-size: 12px;
      font-weight: 700;
      color: $white;

      span {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 992px) {

    #privileges {
      display: flex;
    }

    #auth-footer {
      margin-top: 0;
    }

    .page-content {

      .side {
        width: 450px;

        &.side-left {

          .sms-wrapper {
            margin: auto;
          }
        }

        &.side-right {
          margin-top: 30px;
          margin-left: 50px;
        }
      }

      .link-button {
        font-size: 15px;
      }
    }
  }
}
