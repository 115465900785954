@import "../../../variables";

#faq-anon-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #auth-header-bottom {
    margin: 20px auto;
    width: 95%;
  }

  #faq-page {
    width: 80%;
    max-width: 800px;
    margin: auto;

    .page-content {

      .section-faq-title {

        .info {

          .title,
          .description {
            color: $white;

            a {
              color: $white;
            }
          }
        }
      }

      .section-faq-contact {

        .btn-faq-contact {
          background-color: $secondary-color;
          color: $primary-color;
        }
      }
    }
  }
}
