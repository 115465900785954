@import "../../variables";

#app-layout {
  min-height: 100vh;
  width: 100%;

  .ant-layout {
    min-height: 100vh;
    width: 100%;

    .ant-layout-content {
      min-height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;

      &.bg-page {
        background-color: $page-background;
      }

      &.bg-white {
        background-color: $white;
      }

      .page {
        padding-top: 64px;

        .page-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: auto;

          .form-item-wrapper {
            margin-bottom: 10px;

            .ant-form-item {
              margin-bottom: 0;

              .ant-form-item-explain,
              .ant-form-item-explain-error {
                min-height: 0;
              }

              .ant-form-item-label {
                padding-left: 2px;
                padding-bottom: 2px;

                label {
                  color: #515151;
                  font-size: 13px;
                  letter-spacing: -0.65px;

                  &::before {
                    display: none;
                  }
                }
              }

              .ant-input,
              .form-input,
              .form-date-picker,
              .ant-select-selector {
                height: 42px;
                border-radius: 7px;
              }

              .form-date-picker {
                width: 100%;
              }

              .form-select {

                .ant-select-selection-search-input,
                .ant-select-selection-placeholder,
                .ant-select-selection-item {
                  display: flex;
                  align-items: center;
                }
              }

              .text-area {
                display: flex;
                align-items: center;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
                border-radius: 7px;
                background-color: #ffffff;
              }

              &.ant-form-item-has-error {

                .form-input {
                  border: 1px solid #ff0000;
                }
              }

              .form-input {

                &.border-red {
                  border: 1px solid #ff0000;
                }
              }
            }

            .form-input-info {
              font-style: italic;
              color: #919191;
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .ant-layout {

      .ant-layout-content {

        .page {
          padding-top: 71px;

          .page-content {
            width: $page-width-768;

            .form-item-wrapper {
              margin-bottom: 15px;

              .ant-form-item {

                .ant-form-item-label {

                  label {
                    font-size: 18px;
                    letter-spacing: -0.9px;
                  }
                }

                .ant-input,
                .form-input,
                .form-date-picker,
                .ant-select-selector {
                  height: 62px;
                }
              }

              .form-input-info {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .ant-layout {

      .ant-layout-content {

        .page {
          padding-top: 86px;

          .page-content {
            width: $page-width-1024;

            .form-item-wrapper {
              margin-bottom: 15px;

              .ant-form-item {

                .ant-form-item-label {

                  label {
                    font-size: 18px;
                    letter-spacing: -0.9px;
                  }
                }

                .ant-input,
                .form-input,
                .form-date-picker,
                .ant-select-selector {
                  height: 62px;
                }
              }

              .form-input-info {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .ant-layout {

      .ant-layout-content {

        .page {

          .page-content {
            width: $page-width-1280;
          }
        }
      }
    }
  }
}
