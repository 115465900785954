@import "../../variables";

#campaign-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 135px;

  .body {
    $border-radius: 5px;
    width: 100%;
    max-width: 90px;
    height: 100%;
    background-color: #2A3557;
    border-radius: $border-radius;

    .content {
      display: flex;
      flex-direction: column;
      padding: 14px 10px;
      height: 100%;
      border-radius: $border-radius;

      .card-header {
        display: flex;
        justify-content: center;
        align-items: center;
        clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
        background-color: #3a77e6;
        transform: translateX(-15px);
        width: 90px;
        color: $white;
        font-size: 9px;
        font-weight: 700;
        padding: 2px 0;
      }

      .card-name {
        text-align: center;
        color: $white;
        font-size: 10px;
        font-weight: 700;
        margin: auto;
      }

      .card-button {
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: -0.45px;
        border-radius: 4px;

        &.green {
          background-image: linear-gradient(180deg, #3ff169 0%, #31aa47 100%);
          box-shadow: 0 0 2px #3aff00;
          color: #011738;
        }

        &.white {
          background-image: linear-gradient(180deg, #ffffff 0%, #cbcbcb 100%);
          box-shadow: 0 0 2px #ffffff;
          color: #6e6e6e;

          span {
            opacity: 0.7;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    height: 210px;

    .body {
      width: 145px;
      max-width: 145px;

      .content {
        padding: 22px 17px;

        .card-header {
          transform: translateX(-25px);
          width: 140px;
          font-size: 14px;
          padding: 2px 0;
        }

        .card-name {
          font-size: 17px;
        }

        .card-button {
          height: 31px;
          font-size: 15px;
          letter-spacing: -0.75px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    height: 145px;

    .body {
      width: 100px;
      max-width: 100px;

      .content {
        padding: 15px 12px 8px 12px;

        .card-header {
          transform: translateX(-18px);
          width: 90px;
          font-size: 9px;
          padding: 2px 0;
        }

        .card-name {
          font-size: 13px;
        }

        .card-button {
          height: 22px;
          font-size: 10px;
          letter-spacing: -0.5px;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    height: 180px;

    .body {
      width: 125px;
      max-width: 125px;

      .content {
        padding: 20px 15px 8px 15px;

        .card-header {
          transform: translateX(-20px);
          width: 120px;
          font-size: 11px;
          padding: 2px 0;
        }

        .card-name {
          font-size: 13px;
        }

        .card-button {
          height: 28px;
          font-size: 11px;
          letter-spacing: -0.55px;
        }
      }
    }
  }
}
