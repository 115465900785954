#modal-close-button {
  position: absolute;
  width: 36px;
  height: 36px;
  color: white;
  left: calc(50% - 18px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  bottom: calc(-36px - 25px);
  cursor: pointer;
  z-index: 999;
}
