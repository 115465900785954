$primary-color: #002d72;
$secondary-color: #ffed00;
$secondary-dark-color: #ffed00; // original: #ffcc00
$form-background: #ececec;
$page-background: #f0f2f5;
$white: #ffffff;
$black: #000000;
$gray: #464646;
$light-gray: #d8d8d8;
$dark-gray: #344054;
$lighter-gray: #d0d5dd;
$primary25: #e6eaf1;
$page-width-768: 608px;
$page-width-1024: 608px;
$page-width-1280: 683px;
