@import "../../../../variables";

#login-msisdn-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  #privileges {
    display: none;
  }

  .payment-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin-top: 32px;

    .helper-1 {
      color: $white;
      opacity: 0.8;
      font-size: 10px;
      line-height: 100%;
    }

    .helper-2 {
      color: $white;
      font-size: 9px;
      line-height: 100%;
    }

    .payment-wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 20px;

      img {
        max-height: 30px;
      }

      .payment:nth-child(2) {
        padding-bottom: 2px;
      }

      .payment:last-child {
        padding-left: 20px;
        border-left: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
  }

  @media (min-width: 768px) {

    #privileges {
      display: flex;
    }

    #auth-footer {
      margin-top: 0;
    }
  }

  @media (min-width: 992px) {

    .content-desktop {
      width: 100%;
      max-width: 1000px;
    }

    .payment-info {
      max-width: none;
      padding: 0 20px;
      margin-top: 55px;
    }
  }
}
