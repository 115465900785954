@import "../../variables";

#app-header {
  background-color: $white;
  box-shadow: 0 0 6px rgba(123, 155, 209, 0.4);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  .logo {
    position: absolute;
    height: 100%;
    width: auto;
    padding: 15px 0;
    margin-left: 3rem;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 20px;

    img {
      width: 25px;
      cursor: pointer;

      &:nth-child(4) {
        width: 30px;
      }
    }

    .ant-badge-dot {
      $radius: 7px;
      width: $radius;
      min-width: $radius;
      height: $radius;
    }

    .header-menu {
      border: none;

      .header-submenu {
        border: none;
      }
    }
  }

  @media (min-width: 768px) {
    .content {
      width: $page-width-768;
      padding-left: 0;
      padding-right: 0;

      img {
        width: auto;
      }

      .ant-badge-dot {
        $radius: 10px;
        width: $radius;
        min-width: $radius;
        height: $radius;
      }
    }
  }

  @media (min-width: 1024px) {
    .content {
      width: $page-width-1024;
    }
  }

  @media (min-width: 1280px) {
    .content {
      width: $page-width-1280;
    }
  }
}

.header-menu-item-wrapper {
  background-color: $white !important;

  .header-menu-item {
    display: flex;
    justify-content: flex-end;
    margin-left: 30px;

    .text {
      color: $primary-color;
      font-size: 18px;
      letter-spacing: -0.9px;

      &.logout {
        font-weight: 700;
      }
    }

    .image {
      margin-left: 15px;

      &.bell {
        width: 25px;
        padding-right: 5px;
      }

      &.info {
        width: 22px;
        padding-right: 8px;
      }

      &.question {
        width: 22px;
        padding-right: 4px;
      }

      &.gallery {
        width: 28px;
      }

      &.email {
        width: 25px;
      }
    }
  }
}

.header-divider {
  margin: 0 15px;
  border-top: 1px solid #516cb5;
  opacity: 0.3;
}
