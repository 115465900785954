@import "../../../variables";

#subscription-fail {
  max-width: 300px;

  .content {
    display: flex;
    flex-direction: column;
    color: $white;

    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      margin-top: 10px;
    }

    .text {
      font-size: 12px;
      line-height: 140%;
    }

    #button-primary {
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.05em;
      height: 56px !important;

      &.button-retry {
        background-color: $secondary-color;
        color: $primary-color;
        margin-top: 72px;
      }

      &.button-change-method {
        background-color: transparent;
        color: $secondary-color;
        border: 2px solid $secondary-color;
        margin-top: 8px;
      }
    }

    .button-logout {
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      margin-top: 28px;
    }
  }

  @media (min-width: 768px) {
    max-width: none;

    .content {

      .title {
        font-size: 24px;
      }

      .text {
        font-size: 12px;
      }
    }

    .side {
      width: 450px;

      &.side-left {

        .content {
          width: 300px;
          margin: auto;
        }
      }

      &.side-right {
        margin-top: 0 !important;
        margin-left: 50px;
      }
    }
  }
}