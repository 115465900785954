@import "../../variables";

#campaigns-page {

  .page-content {
    padding-bottom: 30px;

    .page-title {
      text-align: center;
      color: #00307e;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.8px;
      margin: 7px 0;
    }

    .section {
      display: flex;
      flex-direction: column;
      background-color: $white;
      margin-bottom: 6px;
      box-shadow: 0 0 3px rgba(123, 155, 209, 0.4);

      .section-title {
        display: flex;
        justify-content: center;
        align-items: center;
        clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
        background-color: #3a77e6;
        transform: translateX(-15px);
        width: 180px;
        color: $white;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.7px;
        padding: 4px 0;
        margin: 12px 0;
      }

      .cards {
        padding: 0 20px;

        #campaign-card {
          margin-bottom: 20px;
        }

        .empty-image {
          padding-bottom: 20px;
          width: 300px;
        }
      }
    }
  }

  @media (min-width: 768px) {

    .page-content {
      padding-bottom: 40px;

      .page-title {
        font-size: 23px;
        letter-spacing: -1.15px;
        margin: 10px 0;
      }

      .section {
        margin-bottom: 12px;
        box-shadow: 0 0 6px rgba(123, 155, 209, 0.4);
        border-radius: 9px;

        .section-title {
          width: 260px;
          font-size: 21px;
          letter-spacing: -1.05px;
          padding: 2px 0;
          margin: 18px 0;
        }

        .cards {
          padding: 0 60px;

          #campaign-card {
            margin-bottom: 25px;
          }

          .empty-image {
            padding-bottom: 20px;
            width: 400px;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {

    .page-content {
      width: 735px !important;

      .section {
        margin-bottom: 23px;

        .section-title {
          width: 260px;
          font-size: 16px;
          letter-spacing: -0.48px;
          padding: 5px 0;
          margin: 15px 0;
        }

        .cards {
          padding: 0 25px;

          #campaign-card {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {

    .page-content {
      width: 900px !important;

      .page-title {
        margin: 20px 0;
      }

      .section {
        margin-bottom: 28px;

        .section-title {
          width: 310px;
          font-size: 18px;
          letter-spacing: -0.54px;
          padding: 8px 0;
          margin: 20px 0;
        }

        .cards {
          padding: 0 30px;

          #campaign-card {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
