@import "../../variables";

#menu-page {
  color: $primary-color;

  .nav-link-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 25px 0;
    padding-right: 20px;

    span {
      font-size: 18px;
      letter-spacing: -0.9px;
      margin-right: 30px;
    }

    .img-wrapper {
      text-align: center;
      width: 25px;

      .gallery {
        width: 32px;
      }

      .mail {
        width: 30px;
      }
    }
  }

  .line {
    width: 100%;
    border-top: 1px solid #516cb5;
    opacity: 0.3;
  }

  .link-button {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.9px;
    height: auto;
  }

  @media (min-width: 768px) {

    .nav-link-wrapper {
      padding-right: 0;

      span {
        font-size: 22px;
        letter-spacing: -1.1px;
      }

      .img-wrapper {

        .gallery {
          width: 35px;
        }

        .mail {
          width: 35px;
        }
      }
    }

    .link-button {
      font-size: 23px;
      letter-spacing: -1.15px;
    }
  }
}
