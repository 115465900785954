@import "../../../variables";

#create-card-text {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 12px;

  .text {
    color: $primary-color;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    margin-top: 30px;
    margin-bottom: 25px;

    .text {
      font-size: 20px;
    }
  }

  @media (min-width: 1024px) {
    margin-top: 10px;
    margin-bottom: 5px;

    .text {
      font-size: 12px;
    }
  }

  @media (min-width: 1280px) {
    margin-top: 12px;
    margin-bottom: 12px;

    .text {
      font-size: 12px;
    }
  }
}
