@import "../../variables";

#profile-page {
  margin-top: auto;

  .page-content {
    padding-top: 30px;
    padding-bottom: 40px;

    #user-fan-card {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      width: 231px;
    }
  }

  @media (min-width: 768px) {

    .page-content {
      width: 425px !important;
      padding-top: 40px;
      padding-bottom: 64px;

      #user-fan-card {
        margin-bottom: 35px;
        width: 339px;
      }
    }
  }

  @media (min-width: 1024px) {

    .page-content {
      width: 900px !important;
      padding-top: 42px;
      padding-bottom: 72px;

      #user-fan-card {
        margin-bottom: 42px;
        width: 377px;
      }
    }
  }

  @media (min-width: 1280px) {

    .page-content {
      padding-top: 50px;
      padding-bottom: 67px;

      #user-fan-card {
        margin-bottom: 45px;
      }
    }
  }
}
