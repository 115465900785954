@import "../../../variables";

#landing-page {
  background: url(https://s3-eu-west-1.amazonaws.com/static.4p/fenerbahce-landing-bg.png)
    no-repeat center;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  .banner {
    width: 100%;
    background: url("../../../assets/images/landing-banner-bg.png") no-repeat
      center $secondary-color;
    background-size: cover;
    position: relative;

    .banner-gift {
      display: none;
      position: absolute;
      width: 7%;
      max-width: 105px;
      left: 10%;
    }

    .banner-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      gap: 24px;
      font-size: 16px;
      font-weight: 900;
      color: $primary-color;
      text-align: center;

      .banner-btn {
        border-radius: 8px;
        box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.1),
          0px 24px 24px 0px rgba(0, 0, 0, 0.09),
          0px 55px 33px 0px rgba(0, 0, 0, 0.05),
          0px 98px 39px 0px rgba(0, 0, 0, 0.01),
          0px 153px 43px 0px rgba(0, 0, 0, 0);
        color: $secondary-color;
        font-weight: 700;
      }
    }
  }

  .page-content {
    padding: 0 25px;

    .header {
      display: flex;
      flex-direction: column;
      width: 100%;

      #page-title {
        color: $secondary-color;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 4px;
      }

      .subtitle {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: $white;
        margin-bottom: 0;
      }
    }

    .text {
      position: relative;
      font-size: 18px;
      line-height: 21px;
      color: $light-gray;
      padding-left: 30px;
      margin: 35px 0;

      .wheel-wrapper {
        $size: 100px;
        position: absolute;
        cursor: pointer;
        width: $size;
        height: $size;
        top: 50%;
        left: -90px;
        transform: translate(0, -50%);

        .wheel-content {
          position: relative;
          width: $size;
          height: $size;

          .spinning-wheel {
            position: absolute;
            width: 100%;
            height: 100%;
            animation: spin 8s infinite linear;
            @-moz-keyframes spin {
              from {
                -moz-transform: rotate(0deg);
              }
              to {
                -moz-transform: rotate(360deg);
              }
            }
            @-webkit-keyframes spin {
              from {
                -webkit-transform: rotate(0deg);
              }
              to {
                -webkit-transform: rotate(360deg);
              }
            }
            @keyframes spin {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          }

          .button {
            position: absolute;
            width: $size / 3.5;
            height: $size / 3.5;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .pointer {
            position: absolute;
            width: 15px;
            height: auto;
            top: 5px;
            right: 5px;
            transform: rotate(55deg);
          }
        }
      }
    }

    .link {
      width: 100%;

      .button-yellow {
        width: 100%;
        height: 56px;
      }
    }
  }

  @media (min-width: 768px) {
    #auth-footer {
      margin-top: 0;
    }
  }

  @media (min-width: 992px) {
    .banner {
      .banner-gift {
        display: block;
      }
    }

    .page-content {
      .lg-wrapper {
        width: 100%;

        .side {
          &.left {
            width: 60%;
            padding-left: 60px;

            .header {
              #page-title {
                font-size: 35px;
                line-height: 52px;
              }

              .subtitle {
                font-size: 35px;
                line-height: 52px;
              }
            }

            .text {
              font-size: 20px;
              line-height: 28px;
              padding-left: 0;

              .wheel-wrapper {
                $size: 240px;
                width: $size;
                height: $size;
                left: -260px;

                .wheel-content {
                  width: $size;
                  height: $size;

                  .button {
                    width: 60px;
                    height: 60px;
                  }

                  .pointer {
                    width: 30px;
                    top: 18px;
                    right: 18px;
                  }
                }
              }
            }

            .link {
              .button-yellow {
                width: fit-content;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .page-content {
      .lg-wrapper {
        .side {
          &.left {
            .header {
              #page-title {
                font-size: 44px;
              }

              .subtitle {
                font-size: 44px;
              }
            }

            .text {
              font-size: 24px;

              .wheel-wrapper {
                left: -20vw;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .page-content {
      .lg-wrapper {
        .side {
          &.left {
            padding-left: 0;

            .text {
              .wheel-wrapper {
                left: -290px;
              }
            }
          }
        }
      }
    }
  }
}
