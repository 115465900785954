@import "../../../variables";

#wheel-banner {
  .body {
    display: flex;
    background-color: $primary-color;
    padding: 25px 0;

    .side {
      width: 50%;

      &.left {
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 30px;
        padding-bottom: 20px;

        .title {
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: $white;
          margin-bottom: 10px;
        }

        .button {
          display: flex;
          width: fit-content;
          cursor: pointer;
          box-shadow: 0 0 2px #3aff00;
          border-radius: 4px;
          background-image: linear-gradient(180deg, #3ff169 0%, #31aa47 100%);
          color: #011738;
          font-size: 11px;
          font-weight: 700;
          letter-spacing: -0.55px;
          padding: 5px 15px;

          &.disabled {
            cursor: not-allowed;
            background: linear-gradient(180deg, #efefef 0%, #bbbbbb 100%);
            color: rgba(0, 45, 114, 0.25);
          }
        }
      }

      &.right {
        padding-right: 30px;

        .wheel {
          margin-left: auto;
          max-width: 160px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .body {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;

      .side {
        &.left {
          padding-left: 55px;

          .title {
            font-size: 22px;
            line-height: 26px;
          }

          .button {
            font-size: 12px;
          }
        }

        &.right {
          padding-right: 55px;

          .wheel {
            max-width: 215px;
          }
        }
      }
    }
  }
}
