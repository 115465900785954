@import "../../../variables";

#fan-card {
  position: relative;
  width: 250px;

  img {
    width: 100%;
    height: auto;
  }

  .user-info-wrapper {
    position: absolute;
    left: 15px;
    bottom: 15px;
    display: flex;
    flex-direction: column;
    color: $white;

    .fullName {
      opacity: 0.88;
      font-size: 8px;
    }

    .cardNumber {
      font-size: 11px;
    }
  }

  @media (min-width: 768px) {
    width: 350px;

    .user-info-wrapper {
      left: 25px;
      bottom: 25px;

      .fullName {
        font-size: 10px;
      }

      .cardNumber {
        font-size: 15px;
      }
    }
  }

  @media (min-width: 1024px) {
    width: 250px;
  }

  @media (min-width: 1280px) {
    width: 350px;
  }
}
