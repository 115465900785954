@import "../../../../../variables";

#login-otp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;

  .ant-form-item {
    margin-bottom: 0;

    .ant-form-item-explain {
      min-height: 0;
    }

    .form-input {
      background: rgba(255, 255, 255, 0.1) !important;
      color: $white;
      text-align: center;
      width: 190px;
      height: 46px !important;
      margin-top: 25px;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 2px;
    }
  }

  .info {
    text-align: center;
    opacity: 0.69;
    font-size: 10px;
    margin-top: 15px;
    margin-bottom: 35px;
  }

  .recaptcha {
    iframe {
      border: 1px solid transparent;
      border-radius: 3px;
    }
    &.error {
      iframe {
        border-color: red;
      }
    }
  }

  .link-button {
    cursor: pointer;
    text-decoration: underline;
    opacity: 0.8;
    font-size: 9px;
    margin-bottom: 25px;

    &.clicked {
      color: $secondary-color;
      font-weight: bold;
    }
  }

  @media (min-width: 768px) {
    .ant-form-item {
      .form-input {
        width: 250px;
        height: 60px !important;
        margin-top: 35px;
        font-size: 20px;
      }
    }

    .info {
      font-size: 14px;
      margin-top: 18px;
      margin-bottom: 40px;
    }

    .link-button {
      font-size: 11px;
      margin-bottom: 60px;
    }
  }

  @media (min-width: 1024px) {
    .ant-form-item {
      .form-input {
        width: 190px;
        height: 46px !important;
        margin-top: 25px;
      }
    }

    .info {
      font-size: 11px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .link-button {
      font-size: 9px;
      margin-bottom: 25px;
    }
  }

  @media (min-width: 1280px) {
    .ant-form-item {
      .form-input {
        width: 250px;
        height: 60px !important;
        margin-top: 10px;
      }
    }

    .info {
      font-size: 14px;
      margin-top: 18px;
      margin-bottom: 28px;
    }

    .link-button {
      font-size: 14px;
      margin-bottom: 40px;
    }
  }
}
