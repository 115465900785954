@import "../../../variables";

#auth-header-bottom {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 10px auto 20px;

  .link-wrapper {
    display: flex;
    flex-direction: column;
    width: 35%;

    .placeholder {
      height: 35px;
    }

    .line {
      width: 100%;
      margin-top: auto;
      border-top: 1px solid #FFED00;
      margin-bottom: 5px;
    }

    .hash-link {
      color: $white;
      font-weight: bold;
      font-size: 10px;

      .hash {
        color: $secondary-color;
      }
    }

    .faq-link {
      display: flex;
      justify-content: flex-end;
      color: $white;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .logo-heading {
    width: 70px;
    height: 70px;
    margin: 0 20px;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media (min-width: 375px) {

    .link-wrapper {

      .hash-link {
        font-size: 13px;
      }
    }
  }

  @media (min-width: 768px) {
    margin-top: 25px;
    margin-bottom: 20px;
  }

  @media (min-width: 992px) {

    .link-wrapper {
      width: 50%;

      .placeholder {
        height: 60px;
      }

      .line {
        margin-bottom: 10px;
      }

      .hash-link {
        max-width: 100%;
        overflow-wrap: anywhere;
        font-size: 16px;
        padding-left: 15px;
      }

      .faq-link {
        padding-right: 15px;
      }
    }

    .logo-heading {
      width: 150px;
      height: 150px;
    }
  }
}
