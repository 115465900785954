@import "../../../variables";

#contact-us-private-form {

  .ant-form-item {
    margin-bottom: 10px;

    .ant-form-item-explain {
      display: none;
    }

    .text-area {
      padding-top: 10px;
    }
  }
}
