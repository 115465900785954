@import "../../../../variables";

#pay-by-me-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #privileges {
    display: none;
  }

  .page-content {
    margin-top: 15px;

    .failed-subscription {
      display: flex;
      flex-direction: column;
      align-items: center;

      .img-fail {
        width: 86px;
        margin: 30px 0 60px 0;
      }

      .img-fail-icon {
        font-size: 120px;
        color: $white;
        margin: 60px 0;
      }

      .info {
        text-align: center;
        color: $white;
        font-size: 12px;

        &.error {
          color: $white;
          margin-bottom: 40px;
        }
      }

      #button-primary {
        width: 100%;
      }

      .link-button {
        font-size: 12px;
        font-weight: 700;
        color: $white;

        span {
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }

  @media (min-width: 768px) {

    #privileges {
      display: flex;
    }

    #auth-footer {
      margin-top: 0;
    }

    .page-content {

      .failed-subscription {

        .info {
          font-size: 15px;
        }

        .link-button {
          font-size: 15px;
        }
      }
    }
  }

  @media (min-width: 1024px) {

    .page-content {

      .failed-subscription {

        .info {
          font-size: 12px;
        }

        .link-button {
          font-size: 12px;
        }
      }
    }
  }

  @media (min-width: 1280px) {

    .page-content {

      .failed-subscription {

        .info {
          font-size: 15px;
        }

        .link-button {
          font-size: 15px;
        }
      }
    }
  }
}
