@import "../../../../variables";

#contact-us-form {
  width: 300px;
  padding-top: 15px;

  .ant-form-item {
    margin-bottom: 10px;

    .ant-form-item-explain {
      display: none;
    }

    .form-input,
    .ant-select-selector {
      height: 48px !important;
    }

    .text-area {
      padding-top: 10px;
    }
  }

  .title {
    text-align: center;
    color: $white;
    font-size: 21px;
    font-weight: 700;
  }

  #button-primary {
    margin: 15px 0;
  }

  .cancel-link {
    text-decoration: underline;
    color: $white;
    font-size: 16px;
  }

  @media (min-width: 768px) {
    width: 430px;

    .ant-form-item {
      margin-bottom: 12px;

      .form-input,
      .ant-select-selector {
        height: 69px !important;
      }
    }

    .cancel-link {
      font-size: 21px;
    }
  }

  @media (min-width: 1024px) {
    width: 335px;

    .ant-form-item {
      margin-bottom: 8px;

      .form-input,
      .ant-select-selector {
        height: 43px !important;
      }
    }

    .cancel-link {
      font-size: 13px;
    }
  }

  @media (min-width: 1280px) {
    width: 420px;

    .ant-form-item {
      margin-bottom: 10px;

      .form-input,
      .ant-select-selector {
        height: 54px !important;
      }
    }

    .cancel-link {
      font-size: 16px;
    }
  }
}
