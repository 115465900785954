@import "../../variables";

#invite-friends-page {
  background: url(https://s3-eu-west-1.amazonaws.com/static.4p/fenerbahce-landing-bg.png) no-repeat center;
  background-size: cover;
  height: calc(100vh - 57px);

  .page-content {
    padding-top: 70px;

    .section-invite-friends-title {
      .info {
        align-items: center;
        display: flex;
        flex-direction: column;

        span {
          &.title {
            color: #ffee00;
            font-size: 19px;
            font-weight: 700;
            letter-spacing: normal;
            line-height: normal;
          }

          &.text {
            width: 70%;
            text-align: center;
            padding-top: 10px;
            color: $white;
            font-size: 19px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: normal;
          }
        }
      }
    }

    .section-invite-friends-content {
      align-self: center;
      width: 65%;
      background-color: #ffee00;
      border-radius: 7px;
      padding: 20px;
      text-align: -webkit-center;
      margin-top: 30px;

      .input-copy-link {
        border-radius: 7px;
        border: none;
        height: 37px;
        background-color: $white;
      }

      .btn-copy-link {
        border-radius: 11px;
        background-color: $primary-color;
        color: $white;
        padding: 20px 0 20px 0;
        width: 80%;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-top: 20px;
        border: none;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  @media (min-width: 768px) {
    height: calc(100vh - 76px);
    .page-content {
      padding-top: 200px;

      .section-invite-friends-title {
        .info {
          span {
            &.title {
              font-size: 24px;
            }

            &.text {
              padding-top: 25px;
              font-size: 24px;
            }
          }
        }
      }

      .section-invite-friends-content {
        width: 60%;

        .input-copy-link {
          height: 48px;

          .ant-input {
            font-size: 18px;
          }
        }

        .btn-copy-link {
          font-size: 19px;
          padding: 25px 0 25px 0;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    height: calc(100vh - 76px);
    .page-content {
      padding-top: 40px;

      .section-invite-friends-title {
        .info {
          span {
            &.title {
              font-size: 17px;
            }

            &.text {
              width: 50%;
              font-size: 17px;
            }
          }
        }
      }

      .section-invite-friends-content {
        width: 50%;

        .input-copy-link {
          height: 37px;

          .ant-input {
            font-size: 14px;
          }
        }

        .btn-copy-link {
          padding: 20px 0 20px 0;
          font-size: 15px;
        }
      }
    }
  }
  @media (min-width: 1280px) {
    height: calc(100vh - 76px);
    .page-content {
      padding-top: 100px;

      .section-invite-friends-title {
        .info {
          span {
            &.title {
              font-size: 21px;
            }

            &.text {
              font-size: 21px;
            }
          }
        }
      }

      .section-invite-friends-content {
        .input-copy-link {
          height: 44px;

          .ant-input {
            font-size: 16px;
          }
        }

        .btn-copy-link {
          margin-top: 30px;
          padding: 30px 0 30px 0;
          font-size: 16px;
        }
      }
    }
  }
}
