@import "../../variables";

#notifications-page {
  margin-top: auto;
  max-height: 90vh;
  overflow-y: scroll;

  .page-content {
    align-items: center;
    padding: 0 16px 32px;
    max-width: 550px;

    .section-notifications-title {
      .info {
        .title {
          font-size: 14px;
          color: $primary-color;
          font-weight: 400;
          letter-spacing: -0.7px;
          line-height: normal;
        }
      }
    }

    .time-label {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding-left: 4px;
      font-weight: 500;
      font-size: 10px;
      line-height: 18px;
      color: #ADB2BA;
      margin-top: 24px;
      margin-bottom: 8px;

      .pin {
        margin-right: 6px;
      }
    }
  }

  @media (min-width: 768px) {

    .page-content {

      .section-notifications-title {
        .info {
          .title {
            font-size: 21px;
            letter-spacing: -1.05px;
          }
        }
      }

      .time-label {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
