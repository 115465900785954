@import "../../variables";

#photo-gallery-page {
  margin-top: auto;
  max-height: 90vh;
  overflow-y: scroll;

  .page-content {
    align-items: center;
    padding: 20px 16px 40px;

    .section-photos-title {
      .info {
        .title {
          font-size: 14px;
          color: $primary-color;
          font-weight: 400;
          letter-spacing: -0.7px;
          line-height: normal;
        }
      }
    }

    .photo-gallery {
      display: flex;
      flex-direction: column;

      .photo-card {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .date {
          color: $primary-color;
        }

        .image {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  @media (min-width: 768px) {

    .page-content {

      .section-photos-title {
        .info {
          .title {
            font-size: 21px;
            letter-spacing: -1.05px;
          }
        }
      }

      .photo-gallery {

        .photo-card {
          margin-bottom: 30px;
        }
      }
    }
  }
}
