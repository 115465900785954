@import "../../../variables";

#priority-ticket-banner {

  .priority-ticket-body {
    display: flex;
    background: url("../../../assets/images/section-prioirty-ticket-bg.png") no-repeat center;
    background-size: cover;
    padding: 32px;

    .side {
      width: 50%;

      &.side-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 20px;

        .title {
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: $white;
          margin-bottom: 10px;
        }

        .button {
          cursor: pointer;
          background: linear-gradient(180deg, #39ED68 0%, #34B558 100%);
          border-radius: 6px;
          color: #011738;
          font-weight: bold;
          font-size: 11px;
          line-height: 13px;
          padding: 8px 32px;
        }
      }

      &.side-right {
        display: flex;
        justify-content: center;

        .ticket {
          width: 180px;
        }
      }
    }
  }

  @media (min-width: 768px) {

    .priority-ticket-body {
      padding: 35px;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;

      .side {

        &.side-left {
          margin-bottom: 30px;

          .title {
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 15px;
          }

          .button {
            border-radius: 4px;
            font-size: 12px;
            line-height: 14px;
            padding: 7px 20px;
          }
        }

        &.side-right {

          .ticket {
            width: 280px;
          }
        }
      }
    }
  }
}
