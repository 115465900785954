.ant-popover-inner {
  border-radius: 7px;

  .ant-popover-inner-content {

    .share-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        outline: none !important;
        margin: 0 5px;
      }

      .share-button {
        height: 32px;
      }

      .share-icon {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
        }
      }
    }
  }
}
