@import "../../../variables";

.profile-warning-modal {
  width: 312px !important;

  .ant-modal-content {
    border-radius: 9px;

    .ant-modal-body {
      padding: 0;

      .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-bottom: 16px;
        $logoDim: 64px;

        .header {
          position: relative;
          min-height: 50px;
          width: 100%;
          border-top-left-radius: 9px;
          border-top-right-radius: 9px;
          background-color: $primary-color;

          .logo {
            width: $logoDim;
            height: $logoDim;
            position: absolute;
            bottom: -$logoDim / 2;
            left: 50%;
            transform: translate(-50%, 0%);
            border-radius: 100px;
          }
        }

        .message {
          font-size: 14px;
          line-height: 17px;
          color: #474747;
          margin-top: $logoDim / 1.5;
          padding: 0 24px;
        }

        .button {
          background-color: $primary-color;
          border: none;
          color: $white;
          height: 33px;
          border-radius: 8px;
          font-size: 14px;
          line-height: 17px;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 8px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    width: 320px !important;
  }
}
