@import "../../../variables";

#welcome-text {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 15px;

  .title {
    color: #0059e1;
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 0;
  }

  .text {
    color: $white;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    margin-top: 30px;
    margin-bottom: 50px;

    .title {
      font-size: 34px;
      line-height: 39px;
      margin-bottom: 15px;
    }

    .text {
      font-size: 23px;
    }
  }

  @media (min-width: 1024px) {
    margin-top: 10px;
    margin-bottom: 10px;

    .title {
      font-size: 25px;
      line-height: 29px;
      margin-bottom: 0;
    }

    .text {
      font-size: 17px;
    }
  }

  @media (min-width: 1280px) {
    margin-top: 15px;
    margin-bottom: 20px;

    .title {
      font-size: 34px;
      line-height: 39px;
    }

    .text {
      font-size: 22px;
    }
  }
}
