@import "../../variables";

#gift-page {
  margin-top: auto;
  overflow: hidden;

  .page-content {
    align-items: center;
    text-align: center;
    padding-bottom: 45px;
    padding-top: 20px;

    .section-gift {
      text-align: -webkit-center;

      .heading {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .info {
        background-color: #00307e;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        padding-inline: 20px;

        span {
          color: $white;
          font-size: 24px;
          font-weight: 700;
          letter-spacing: -1.2px;
          line-height: normal;
        }
      }
    }

    .section-gift-description {
      margin-top: 40px;
      max-width: 88%;

      .info {
        display: flex;
        flex-direction: column;

        .title {
          color: #00307e;
          font-size: 15px;
          font-weight: 700;
          letter-spacing: normal;
          line-height: normal;
        }

        .text {
          align-self: center;
          color: #565656;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: -0.55px;
          line-height: normal;
          text-align: left;
          padding-top: 10px;
        }
      }
    }

    .section-gift-option {
      max-width: 88%;

      .info {
        display: flex;
        flex-direction: column;

        .title {
          margin-top: 10px;
          font-size: 12px;
          color: #183788;
          font-weight: 700;
          letter-spacing: normal;
          line-height: normal;
          text-align: left;
          text-decoration: underline;
        }

        .text {
          color: #565656;
          font-size: 11px;
          font-weight: 400;
          letter-spacing: -0.55px;
          line-height: normal;
          text-align: left;
          padding-top: 5px;
        }
      }
    }

    .placeholder {
      transform: translateX(20px);
      max-width: 500px;
    }
  }

  @media (min-width: 768px) {
    .page-content {
      .section-gift {
        .heading {
          img {
            width: 28%;
          }
        }

        .info {
          height: 43px;
          margin-top: 10px;

          span {
            font-size: 28px;
            letter-spacing: -1.4px;
          }
        }
      }

      .section-gift-description {
        margin-top: 80px;

        .info {
          .title {
            font-size: 24px;
          }

          .text {
            font-size: 16px;
            letter-spacing: -0.48px;
          }
        }
      }

      .section-gift-option {
        .info {
          .title {
            font-size: 19px;
            margin-top: 20px;
          }

          .text {
            font-size: 16px;
            letter-spacing: -0.48px;
            padding-top: 10px;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .page-content {
      .section-gift {
        .heading {
          img {
            width: 23%;
          }
        }

        .info {
          height: 35px;
          margin-top: 10px;

          span {
            font-size: 24px;
            letter-spacing: -1.2px;
          }
        }
      }

      .section-gift-description {
        margin-top: 10px;

        .info {
          .title {
            font-size: 15px;
          }

          .text {
            font-size: 11px;
            letter-spacing: -0.55px;
          }
        }
      }

      .section-gift-option {
        max-width: 56%;

        .info {
          .title {
            font-size: 12px;
            margin-top: 10px;
          }

          .text {
            font-size: 11px;
            letter-spacing: -0.55px;
            padding-top: 5px;
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .page-content {
      .section-gift {
        .heading {
          img {
            width: 25%;
          }
        }

        .info {
          height: 43px;
          margin-top: 10px;

          span {
            font-size: 33px;
            letter-spacing: -1.45px;
          }
        }
      }

      .section-gift-description {
        margin-top: 10px;

        .info {
          .title {
            font-size: 18px;
          }

          .text {
            font-size: 15px;
            letter-spacing: -0.7px;
          }
        }
      }

      .section-gift-option {
        max-width: 63%;

        .info {
          .title {
            font-size: 16px;
            margin-top: 10px;
          }

          .text {
            font-size: 14px;
            letter-spacing: -0.7px;
            padding-top: 5px;
          }
        }
      }
    }
  }
}
