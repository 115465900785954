@import "../../../../../variables";

#login-msisdn-form {
  width: 300px;
  color: $gray;

  #fan-card {
    width: 100% !important;
  }

  .msisdn-note {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: $white;
    margin-top: 40px;
    margin-bottom: 8px;
  }

  .ant-radio-group {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    margin: 5px 0;

    .ant-radio-button-wrapper {
      width: 50%;
      border-radius: 6px;
      border: none;
      box-shadow: none;
      color: $white;
      background-color: transparent;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      padding: 0;
      height: 30px;

      &::before {
        background-color: transparent;
      }

      &.ant-radio-button-wrapper-checked {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        background: $secondary-color;
        color: $primary-color;
      }
    }
  }

  .checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    &.privacy-consent {
      justify-content: flex-start;
    }

    .ant-form-item {
      margin: 0;
    }

    .checkbox-text {
      color: $white;
      font-size: 12px;
      font-weight: 400;

      .link {
        color: $white;
        font-weight: 700;
      }
    }
  }

  .contracts {
    color: $white;
    font-size: 9px;
    line-height: 100%;
    text-align: center;
    margin-top: 16px;

    a {
      color: $white;
      font-weight: bold;
    }
  }

  .ant-form-item {
    margin: 10px auto 5px auto;

    .ant-input-group-addon {
      background-color: transparent;
      border: none;

      .select-before {
        width: 100px;

        .ant-select-selector {
          height: 40px !important;
          border: none !important;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }
    }

    .ant-input {
      height: 40px;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      border: none;

      &:focus,
      &:active {
        box-shadow: none;
      }
    }

    .ant-form-item-explain,
    .ant-form-item-explain-error {
      min-height: 0;
    }

    .react-tel-input {
      .phone {
        width: 100%;
        background: rgba(255, 255, 255, 0.1) !important;
        color: $white;

        &.error-border {
          border: 1px solid red !important;
        }
      }

      .flag-dropdown {
        border-radius: 7px 0 0 7px;
        background: rgba(255, 255, 255, 0.2);

        .selected-flag {
          border-radius: 7px 0 0 7px;
        }
      }

      .country-list {
        width: 310px;
        text-align: justify;
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        border-radius: 7px;
      }
    }
  }

  .recaptcha {
    iframe {
      border: 1px solid transparent;
      border-radius: 3px;
    }
    &.error {
      iframe {
        border-color: red;
      }
    }
  }

  .info {
    color: $white;
    opacity: 0.69;
    font-size: 10px;
    text-align: center;
    margin-bottom: 15px;
  }

  #button-primary {
    width: 100%;
  }

  .link-button {
    height: 42px;
    border-radius: 11px;
    border: 1px solid $primary-color;
    background-color: rgba(255, 255, 255, 0.5);
    color: $primary-color;
    font-size: 11px;
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .signup-link {
    text-decoration: underline;
    font-size: 11px;
    font-weight: 700;
    opacity: 0.75;
    color: $white;
  }

  @media (min-width: 768px) {
    .info {
      font-size: 11px;
      margin-bottom: 20px;
    }

    .helper-1 {
      font-size: 10px;
      margin-top: 20px;
      margin-bottom: 28px;
    }

    .link-button {
      height: 55px;
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 35px;
    }

    .signup-link {
      font-size: 14px;
    }
  }

  @media (min-width: 1024px) {
    .info {
      font-size: 12px;
      margin-bottom: 8px;
    }

    .helper-1 {
      transform: translateX(-10%);
      width: 130%;
      margin-bottom: 10px;
    }

    .helper-2 {
      transform: translateX(-10%);
      width: 130%;
      margin-bottom: 10px;
    }

    .link-button {
      height: 47px;
      font-size: 14px;
      margin-top: 8px;
      margin-bottom: 10px;
    }
  }

  @media (min-width: 1280px) {
    .info {
      font-size: 11px;
      margin-bottom: 20px;
    }

    .link-button {
      height: 56px;
      font-size: 12px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}
