@import "../../variables";

#faq-page {
  margin-top: auto;

  .page-content {
    padding-bottom: 45px;

    .section-faq-title {
      .info {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 20px 0 20px 0;

        .title {
          color: $primary-color;
          font-size: 17px;
          font-weight: 700;
          letter-spacing: -0.85px;
          line-height: normal;
        }

        .description {
          margin-top: 10px;
          color: $primary-color;
          font-size: 15px;
        }
      }
    }

    .section-faq-content {
      .info {
        display: flex;
        flex-direction: column;

        .faq-items {
          background-color: $white;
          padding: 20px 20px 10px 20px;
        }

        .faq-items:nth-child(2n + 1) {
          color: $primary-color;
          font-size: 15px;
          font-weight: 700;
          letter-spacing: -0.75px;
          line-height: normal;
          margin-top: 10px;
        }

        .faq-items:nth-child(2n) {
          color: $primary-color;
          font-size: 14px;
          letter-spacing: -0.7px;
          line-height: normal;
          padding-top: 5px;
        }
      }
    }

    .section-faq-contact {
      .body {
        display: flex;
        justify-content: center;

        .btn-faq-contact {
          width: 50%;
          background-color: $primary-color;
          height: auto;
          box-shadow: 0 0 8px rgba(123, 155, 209, 0.4);
          border-radius: 11px;
          color: $white;
          font-size: 17px;
          font-weight: 500;
          letter-spacing: normal;
          line-height: normal;
          padding: 10px;
          margin-top: 3rem;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .page-content {
      padding-bottom: 45px;

      .section-faq-title {
        .info {
          padding: 20px 0 20px 0;
        }
      }

      .section-faq-content {
        .info {
          .faq-items {
            padding: 30px 30px 20px 30px;
          }

          .faq-items:nth-child(2n + 1) {
            font-size: 20px;
            letter-spacing: -1px;
            margin-top: 10px;
          }

          .faq-items:nth-child(2n) {
            font-size: 19px;
            letter-spacing: -1px;
            padding-top: 5px;
          }
        }
      }

      .section-faq-contact {
        .body {
          .btn-faq-contact {
            width: 50%;
            font-size: 21px;
            padding: 10px;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .page-content {
      padding-bottom: 45px;

      .section-faq-title {
        .info {
          padding: 20px 0 20px 0;
        }
      }

      .section-faq-content {
        .info {
          .faq-items {
            padding: 20px 20px 15px 20px;
          }

          .faq-items:nth-child(2n + 1) {
            font-size: 18px;
            letter-spacing: -0.8px;
            margin-top: 10px;
          }

          .faq-items:nth-child(2n) {
            font-size: 17px;
            letter-spacing: -0.8px;
            padding-top: 5px;
          }
        }
      }

      .section-faq-contact {
        .body {
          .btn-faq-contact {
            width: 50%;
            font-size: 20px;
            padding: 10px;
          }
        }
      }
    }
  }
}
