@import "../../../variables";

#test-question {

  .question-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    .no {
      position: absolute;
      top: 4px;
      left: -15px;
      font-weight: bold;
      font-size: 14px;
      color: $secondary-color;

      &.big {
        left: -25px;
      }
    }

    .question {
      font-size: 20px;
      line-height: 28px;
      color: #F9F9F9;
      margin-bottom: 16px;
    }

    .answers {
      height: 310px;
      overflow-y: scroll;

      .answer {
        cursor: pointer;
        padding: 4px;
        display: flex;
        align-items: center;
        background: rgba(249, 249, 249, 0.5);
        border: 1px solid #B3B3B3;
        box-sizing: border-box;
        border-radius: 4px;
        margin-top: 8px;

        &.selected {
          background: $secondary-color;
          border: 1px solid $secondary-color;

          .choice {
            background: $primary-color;
            color: #F9F9F9;
          }

          .text {
            color: $primary-color;
          }
        }

        .choice {
          cursor: pointer;
          min-width: 24px;
          min-height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(255, 255, 255, 0.75);
          border-radius: 4px;
          font-weight: 500;
          font-size: 12px;
          margin-right: 8px;
          margin-bottom: auto;
        }

        .text {
          cursor: pointer;
          font-size: 16px;
          line-height: 24px;
          color: #F9F9F9;
        }
      }
    }
  }

  .progress-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;

    .test-navigator {
      cursor: pointer;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: #F9F9F9;
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    .test-progress {
      display: flex;
      flex-direction: column;
      width: 120px;

      .info {
        color: $secondary-color;
        font-size: 12px;
      }

      .ant-progress {

        &.ant-progress-line {
          font-size: 9px;
        }

        .ant-progress-inner {
          background-color: #BDBDBD;
        }
      }
    }
  }

  @media (min-width: 768px) {

    .progress-wrapper {
      margin-top: 120px;
    }
  }

  @media (min-width: 992px) {

    .question-wrapper {

      .answers {

        .answer {

          &:hover {
            background: rgba(255, 244, 99, 0.5);
            border: 1px solid $secondary-color;
          }
        }
      }
    }
  }
}
