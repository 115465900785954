#contact-us-private-page {

  .page-content {
    padding-top: 50px;

    .success-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 100px;

      .image {
        width: 86px;
        height: 86px;
      }

      .text {
        text-align: center;
        color: #129e3b;
        font-size: 12px;
        margin-top: 60px;
        margin-bottom: 120px;
      }
    }
  }

  @media (min-width: 768px) {

    .page-content {
      padding-top: 50px;

      .success-wrapper {
        padding-top: 100px;

        .image {
          width: 110px;
          height: 110px;
        }

        .text {
          font-size: 15px;
          margin-top: 100px;
          margin-bottom: 130px;
        }
      }
    }
  }
}
