@import "../../../../variables";

#poll-result {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $primary-color;

  .poll-option {
    cursor: auto;

    .option {

      &.selected {
        background: $white;
        border: 5px solid $primary-color;
      }
    }

    .percentage {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-left: auto;
    }
  }

  .poll-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(195, 209, 234, 0.15);
    width: 120%;
    height: 38px;
    transform: translate(-9%);
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    margin-top: 30px;

    .days {
      padding: 2px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      background-color: $secondary-color;
      margin-left: 2px;
    }
  }
}
