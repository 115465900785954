@import "../../variables";

#user-fan-card {
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  .user-info-wrapper {
    position: absolute;
    left: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    color: $white;

    .fullName {
      opacity: 0.88;
      font-size: 8px;
    }

    .cardNumber {
      font-size: 11px;
    }
  }

  .copy-wrapper {
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 15px;
    color: $white;
    font-size: 10px;

    .copy {
      margin-right: 2px;
      width: 15px;
      height: 15px;
    }
  }

  @media (min-width: 768px) {
    .user-info-wrapper {
      left: 20px;
      bottom: 20px;

      .fullName {
        font-size: 10px;
      }

      .cardNumber {
        font-size: 12px;
      }
    }

    .copy-wrapper {
      right: 25px;
      bottom: 25px;
      font-size: 12px;
    }
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1280px) {
    .user-info-wrapper {

      .cardNumber {
        font-size: 12px;
      }
    }
  }
}

.copy-message {

  .ant-message-notice-content {
    background: #406295;
    border-radius: 4px;
    color: $white;
    font-size: 14px;
    font-weight: 600;
  }
}
