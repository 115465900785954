@import "../../../../variables";

#login-otp-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #welcome-text {
    margin-bottom: 0;
  }

  #fan-card {
    margin-top: 25px;
    margin-bottom: 1rem;
  }

  .link {
    text-decoration: underline;
    font-weight: bold;
    color: $white;
  }

  @media (min-width: 768px) {
    #fan-card {
      margin-top: 55px;
    }
  }

  @media (min-width: 1024px) {
    #fan-card {
      margin-top: 15px;
    }
  }

  @media (min-width: 1280px) {
    #fan-card {
      margin-top: 25px;
    }
  }
}
