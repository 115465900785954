@import "../../../variables";

#profile-form {

  //<editor-fold desc="Special requirement for fenerbahce :)">
  .ant-input {
    box-shadow: none;
  }

  .ant-form-item-has-error {

    .ant-input {
      border: 1px solid $primary-color;
    }

    .ant-form-item-explain-error {
      color: $primary-color;
    }
  }

  //</editor-fold>

  .label-wrapper {
    display: flex;
    align-items: center;

    .label-icon {
      height: 20px;
      margin-right: 5px;

      &.small {
        height: 15px;
      }
    }
  }

  .ant-radio-group {
    width: 100%;
    background-color: $white;

    .ant-radio-button-wrapper {
      width: 50%;
      border: none;
      box-shadow: none;
      color: #6a6a6a;
      text-align: center;
      font-size: 14px;

      &::before {
        background-color: transparent;
      }

      &:first-child {
        border-top-left-radius: 7px;
      }

      &:last-child {
        border-top-right-radius: 7px;
      }

      &.ant-radio-button-wrapper-checked {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        background-color: #89afdb;
        color: $white;
      }
    }
  }

  .form-input.identity {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    &.no-border {
      border: none !important;
    }

    .ant-input-group-addon {
      background-color: transparent;
      border: none;
      padding-right: 0;
    }
  }

  #button-primary {
    font-size: 12px;

    &.button-dark-yellow {
      border: none;
    }

    &.button-consents {
      background-color: transparent;
      color: $primary-color;
    }
  }

  .button-full-width {

    #button-primary {
      width: 100%;
    }
  }

  .contact-link {
    text-decoration: underline;
    font-size: 12px;
    font-weight: 700;
  }

  @media (min-width: 768px) {

    #button-primary {
      height: 62px;
      font-size: 16px;
    }

    .contact-link {
      font-size: 14px;
    }
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1280px) {
  }
}
