@import "../../../variables";

#subscription-page {
  background: url(https://s3-eu-west-1.amazonaws.com/static.4p/fenerbahce-landing-bg.png) no-repeat center;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;

  .page-content {
    padding: 0 15px;

    .mobile-info {
      min-width: 180px;
      display: flex;
      flex-direction: column;
      font-weight: 500;

      .title {
        color: $secondary-color;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 8px;
      }

      .text {
        color: $white;
        font-size: 14px;
        line-height: 17px;
      }
    }

    .privileges {
      .privilege-wrapper {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

        .privilege-info {
          display: flex;
          flex-direction: column;
          margin-left: 8px;
          color: $white;

          .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 4px;
          }

          .text {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      .end-note {
        width: 100%;
        color: #a1b5d3;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .option-wrapper {
      min-width: 315px;
      max-width: 315px;
      display: flex;
      flex-direction: column;
      align-self: center;
      margin-top: 32px;

      .option-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $secondary-color;
        margin-bottom: 8px;
      }

      .option {
        position: relative;
        cursor: pointer;
        width: 145px;
        height: 100px;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(255, 255, 255, 0.25);
        border-radius: 8px;
        padding-top: 6px;
        padding-left: 16px;
        background: rgba(255, 255, 255, 0.15);

        &.foreign {
          width: 100%;
          justify-content: center;
          padding-top: 0;
        }

        &.selected {
          border: 2px solid $secondary-color;
          background: rgba(255, 237, 0, 0.25);
        }

        &.yearly {
          .price-original {
            color: $white;
            text-decoration: line-through $secondary-color;
          }

          .note {
            color: $white;
          }
        }

        .selection-icon {
          position: absolute;
          width: 24px;
          height: 24px;
          top: 10px;
          right: 10px;
        }

        .discount {
          position: absolute;
          font-weight: 500;
          font-size: 8px;
          line-height: 12px;
          padding: 2px 6px;
          border-radius: 4px;
          color: $primary-color;
          background: $secondary-color;
          top: -8px;
          left: 50%;
          transform: translate(-50%);
        }

        .price-original {
          color: transparent;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
        }

        .price {
          color: $white;
          font-size: 16px;
          line-height: 19px;

          .main {
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;

            .float {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }

        .note {
          color: transparent;
          font-size: 10px;
          line-height: 12px;
        }

        .method-icon {
          width: auto;
          height: 20px;
          margin-top: 12px;
          margin-bottom: 4px;
          margin-right: auto;
        }

        .method {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: $white;
        }
      }

      .recaptcha {
        iframe {
          border: 1px solid transparent;
          border-radius: 3px;
        }
        &.error {
          iframe {
            border-color: red;
          }
        }
      }

      .button-yellow {
        height: 56px !important;
        margin-top: 20px;
        padding: 4px 22px;

        .button-text {
          width: 100%;
        }

        .button-icon {
          margin-left: auto;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .page-content {
      padding: 0;

      .privileges {
        .end-note {
          color: $white;
          margin-top: 40px;
          margin-right: 150px;
        }
      }

      .desktop-box {
        min-width: 385px;

        #page-title {
          color: $secondary-color;
          margin-bottom: 5px;
        }

        .subtitle {
          font-weight: 500;
          font-size: 24px;
          line-height: 140%;
          color: $white;
          margin-bottom: 32px;
        }

        .note {
          font-size: 16px;
          line-height: 140%;
          color: $white;
        }
      }

      .col-left {
        margin-left: 140px;
        margin-right: 20px;
      }

      .col-right {
        display: flex;
        flex-direction: column;
        align-items: center;

        #fb-card {
          max-width: 450px;
        }
      }
    }

    @media (min-width: 1200px) {
      .page-content {
        .privileges {
          width: 600px;
        }
      }
    }
  }
}
