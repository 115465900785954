@import "../../../variables";

#fb-card {
  margin-bottom: 20px;
  position: relative;

  img {
    width: 100%;
  }

  &.hasBadge {
    margin-top: 28px;
  }

  .badge {
    position: absolute;
    right: -7vw;
    top: -7vw;
    width: 20vw;
    aspect-ratio: square;
  }

  @media (min-width: 576px) {
    &.hasBadge {
      margin-top: 60px;
    }

    .badge {
      right: -38px;
      top: -60px;
      width: 128px;
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 0;

    &.hasBadge {
      margin-top: 0;
    }
  }
}
